import store from "../../index";
import md5 from "react-native-md5";

export const setPK = test => {
  return {
    type: "SET_PK",
    payloadPKs: test
  };
};
export const setAcitveCategory = (indexCategory) => {
  return {
    type: "SET_ACTIVE_CATEGORY",
    payloadIndexCategory: indexCategory
  };
};
export const setAcitveAllCategory = () => {
  return {
    type: "SET_ACTIVE_ALL"
  };
};
export const changeInputText = (text, index) => {
  return {
    type: "CHANGE_METADATA",
    payloadText: text,
    payloadIndex: index
  };
};
export const changeReportText = (text) => {
  return {
    type: "CHANGE_TEXT",
    payloadReportText: text,
  };
};
export const parseData = (data) => {
  return {
    type: "PARSE_DATA",
    payloadData: data,
  };
};
export const test = () => {
  return {
    type: "TEST"
  };
};
export const addObjects = (firstIndex, secondIndex) => {
  return {
    type: "ADD_OBJECTS",
    payloadFirstIndex: firstIndex,
    payloadSecondIndex: secondIndex
  };
};
export const deleteObjects = (firstIndex, secondIndex, thirdIndex) => {
  return {
    type: "DELETE_OBJECTS",
    payloadFirstIndex: firstIndex,
    payloadSecondIndex: secondIndex,
    payloadDeletedIndex: thirdIndex
  };
};
export const changeTextControls = (firstIndex, secondIndex, thirdIndex, fouthIndex, text) => {
  return {
    type: "CHANGE_TEXT_CONTROLS",
    payloadFirstIndex: firstIndex,
    payloadSecondIndex: secondIndex,
    payloadThirdIndex: thirdIndex,
    payloadFouthIndex: fouthIndex,
    payloadText: text
  };
};
export const changeRadio = (firstIndex, secondIndex, thirdIndex, fouthIndex, choice) => {
  return {
    type: "CHANGE_RADIO",
    payloadFirstIndex: firstIndex,
    payloadSecondIndex: secondIndex,
    payloadThirdIndex: thirdIndex,
    payloadFouthIndex: fouthIndex,
    payloadChoice: choice
  };
};
export const changeCheck = (firstIndex, secondIndex, thirdIndex, fouthIndex, Check) => {
  return {
    type: "CHANGE_CHECK",
    payloadFirstIndex: firstIndex,
    payloadSecondIndex: secondIndex,
    payloadThirdIndex: thirdIndex,
    payloadFouthIndex: fouthIndex,
    payloadCheck: Check
  };
};

export const updateGeneratedSentenceText = (firstIndex, secondIndex, arrIndex, generatedSentence) => {
  return {
    type: "UPDATE_GENERATED_SENTENCE_TEXT",
    payloadFirstIndex: firstIndex,
    payloadSecondIndex: secondIndex,
    payloadThirdIndex: arrIndex,
    payloadGeneratedSentence: generatedSentence
  };
};

export const onLoadReport = (data, index) => {
  return {
    type: "ON_LOAD_REPORT",
    payloadReportData: data,
    payloadReportIndex: index
  };
};

export const changeFieldOfStudy = (pk, data) => {
  let fieldOfStudy = "";
  data.forEach(function (d, i) {
    if (d.pk === pk) {
      fieldOfStudy = d.examination_area;
    }
  });
  //debugger;
  return {
    type: "SET_FIELD_OF_STUDY",
    fieldOfStudy: fieldOfStudy
  };
};

export const popupForSave = (popupData) => {
  let tempPopupData = popupData;
  tempPopupData.push({
    buttonType: "O",
    text: ["Заключение сохранено"],
    popupType: "simple",
    headColor: "default",
    buttonEvent: {}
  });
  return {
    type: "CHANGE_POPUP_DATA",
    popupData: tempPopupData
  };
};

export const saveMD5 = (md5) => {
  return {
    type: "SET_MD5",
    md5: md5
  };
};

export const saveMD5controls = (md5) => {
  return {
    type: "SET_MD5_CONTROLS",
    md5: md5
  };
};

export const saveAdditionalMD5 = (md5) => {
  return {
    type: "SET_MD5_ADDITIONAL",
    md5: md5
  };
};

export const createPopupReportList = (store, text, buttonsType, buttonsEvent, headColor = "warning", popupType = "simple") => {
  let tempPopupData = store.getState().workState.popupData;
  tempPopupData.push({
    buttonType: buttonsType,
    text: text,
    popupType: popupType,
    headColor: headColor,
    buttonEvent: buttonsEvent
  });
  store.dispatch({
    type: "CHANGE_POPUP_DATA",
    popupData: tempPopupData
  });

};

export const setDefaultWorkstate = () => {
  return {
    type: "SET_DEFAULT_WORKSTATE"
  };
};

export const setToCurrentMD5 = () => {
  store.dispatch({
    type: "SET_MD5_ADDITIONAL",
    md5: md5.hex_md5(store.getState().editorState.metaData[0] + store.getState().editorState.metaData[1] + store.getState().editorState.metaData[2])
  });

  if (store.getState().editorState.generatedText !== "") {
    store.dispatch({
      type: "SET_MD5",
      md5: md5.hex_md5(store.getState().editorState.generatedText)
    });
  }

  let res = store.getState().editorState.categoryArray.map(a => ({ ...a }));
  let tmpCategoryArray = {};
  res.map(function (data, index) {
    delete (data.visible);
    return tmpCategoryArray[index] = data;
  });

  store.dispatch({
    type: "SET_MD5_CONTROLS",
    md5: md5.hex_md5(JSON.stringify(tmpCategoryArray))
  });
};

export const setImageArray = (imgData) => {
  return {
    type: "SET_IMAGES_ARRAY",
    imgData: imgData
  };
};