import React from "react";
import { connect } from "react-redux";
import { onChangeCurrentPanel } from "../../../store/settings/actions";
import { userLogo, QRLogo, QRLogoFav, supportIcon, helpIcon } from "./images/images";
import tmpObj from "../workPanel/reportEditor/checkModReport";
import { createPopupReportList } from "../../../store/editorState/actions";
import "./topPanel.css";
import store from "../../../index";
import $ from "jquery";
import workWithPayments from "../workPanel/reportEditor/workWithPayments";

function TopPanel(props) {
  const currentProps = props;
  var WWP = new workWithPayments();
  return (
    <div className="topPanelMainContainer">
      <div className="leftSubPanel">
        <div onClick={() => {
          if ($(".fastInsertBody").length > 0) {
            store.dispatch({
              type: "CHANGE_SEARCH_TEXT_FI",
              payloadText: ""
            });
            store.dispatch({
              type: "TOOGLE_FAST_INSERT"
            });
          }
          else {
            store.dispatch({
              type: "SET_WORKPANEL"
            });
          }
        }} className="leftSubPanel_logo">
          <img alt="logo" src="/qr_logo_2_white.png" />
        </div>

      </div>
      <div className="centerSubPanel">
        <div className="centerSubPanel_logoText"><span>QUICK RADIOLOGY</span></div>
      </div>
      <div className="rightSubPanel">
        <div className="rightSubPanel_loginText">{currentProps.userFio}</div>
        <div className="rightSubPanel_people" onClick={() => {
          WWP.checkPayments(() => {
            tmpObj.check(() => {
              store.dispatch({
                type: "CHANGE_CURRENTPANEL"
              });
            });
          });
        }}>
          {userLogo}
        </div>
        <div className="rigthSubpanel_support" onClick={() => {
          createPopupReportList(store,
            ["Система управления медицинскими заключениями.", <br />, "Служба поддержки: support@quickradiology.com"],
            "O", {}, "default");  
        }}>
          {supportIcon}
        </div>
        <div className="rigthSubpanel_support" onClick={() => {      
          store.dispatch({
            type: "SET_DEFAULT_RIGHTPANEL"
          })
          store.dispatch({
            type: "DESELECT_REPORT"
          })        
        }}>
          {helpIcon}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  onChangeCurrentPanel
};

const mapStateToProps = state => {
  return {
    userFio: state.settings.userFio,
    currentPanel: state.settings.currentPanel
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopPanel);