import React, { useState, useEffect } from "react";
import "../../components/textInput/textInput.css";

export default function textInputCustom(props) {
  const currentProps = props;
  const [inputValue, setInputValue] = useState(currentProps.inputValue);
  useEffect(() => {
    setInputValue(currentProps.inputValue);
  }, [currentProps.inputValue]);
  return (
    <div className="textInput">
      <span>{currentProps.inputHeader}</span>
      <input
        max="2099-01-01"
        type={currentProps.type}
        value={inputValue}
        onChange={(e) => { setInputValue(e.target.value); }}//new Intl.DateTimeFormat('ru-RU').format(new Date(e.target.value))
        onBlur={(e) => { currentProps.changeText(e.target.value, currentProps.index); }} />
    </div>
  );
}