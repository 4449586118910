import React, { useEffect, useState } from "react";
import "./leftMenu.css";
import { createRepIcon } from "../images/images";
import { onCreateNewReport } from "../../../../store/leftMenu/actions";
import { saveMD5controls } from "../../../../store/editorState/actions";
import { connect } from "react-redux";
import tmpObj from "../reportEditor/checkModReport";
import store from "../../../../index";
import workWithServer from "../reportEditor/workWithServer";

function newReport(props) {
  const [active, setActive] = useState(false);
  const serverImage = new workWithServer();

  useEffect(() => {
    if (props.rightPanel === "CategorySelector") {
      setActive(true);
    }
    if (props.rightPanel === "default") {
      setActive(false);
    }
  }, [props.rightPanel]);

  useEffect(() => {
    if (props.reportPK) {
      setActive(false);
    }
  }, [props.reportPK]);

  useEffect(() => {
    setActive(false);
  }, [props.tabID]);

  return (
    <div
      onClick={() => {
        tmpObj.check(() => {
          props.onCreateNewReport();
          store.dispatch({
            type: "SET_MD5_CONTROLS",
            md5: ""
          });
          store.dispatch({
            type: "SET_MD5",
            md5: ""
          });
          store.dispatch({
            type: "CHANGE_TEXT",
            payloadReportText: ""
          });
          serverImage.WWS.onSelectReport();
        });
      }}
      className="leftMenuCreateReports">
      <div id={"new_report_button"} className={active ? "leftMenuCreateReportsSelected" : ""}>
        {createRepIcon}
        <span id={"new_report_button_text"}>Новое заключение</span>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  onCreateNewReport,
  saveMD5controls
};

const mapStateToProps = store => {
  return {
    rightPanel: store.leftMenu.rightPanel,
    tabID: store.leftMenu.currentTab.tabID,
    reportPK: store.editorState.reportPK
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(newReport);