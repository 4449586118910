import React from "react";
import "./leftMenu.css";
import { saveMD5, saveMD5controls, saveAdditionalMD5 } from "../../../../store/editorState/actions";
import { changeBtnNewDel } from "../../../../store/leftMenu/actions";
import { connect } from "react-redux";
import tmpObj from "../reportEditor/checkModReport";
import store from "../../../../index";
import md5 from "react-native-md5";
import { selectCurrentReport } from "../../../../store/leftMenu/actions";

function NewDelBtn(props) {
  const currentProps = props;

  const resetMD5 = () => {
    let res = store.getState().editorState.categoryArray.map(a => ({ ...a }));
    let tmpCategoryArray = {};
    res.map(function (data, index) {
      delete (data.visible);
      return tmpCategoryArray[index] = data;
    });
    currentProps.saveMD5controls(md5.hex_md5(JSON.stringify(tmpCategoryArray)));
    if (store.getState().editorState.generatedText !== "") {
      currentProps.saveMD5(md5.hex_md5(store.getState().editorState.generatedText));
    }
    if (store.getState().editorState.metaData[0] !== "") {
      currentProps.saveAdditionalMD5(md5.hex_md5(store.getState().editorState.metaData[0] + store.getState().editorState.metaData[1] + store.getState().editorState.metaData[2]));
    }
    const status = JSON.stringify(store.getState().leftMenu.reportsData).indexOf("\"pk\":" + store.getState().leftMenu.selectedReport);
    //debugger;
    if (status !== -1 || store.getState().leftMenu.rightPanel !== "default") {
      //Если произошел переход от выделенного репорта
      store.dispatch({
        type: "SET_DEFAULT_RIGHTPANEL"
      });
    }
    else {
      selectCurrentReport(store.getState().leftMenu.selectedReport, store.getState().leftMenu.selectedReport);
    }
  };

  return (
    <div className="leftMenuCoD">
      <div>
        <div className={`CoD ${currentProps.deleted ? " CoDunselected" : "CoDleft"}`}
          onClick={() => {
            tmpObj.check(() => {
              currentProps.changeBtnNewDel(false);
              resetMD5();
            });
          }}>
          <span>Созданные</span>
        </div>
        <div className={`CoD ${!currentProps.deleted ? " CoDunselected" : "CoDright"}`}
          onClick={() => {
            tmpObj.check(() => {
              currentProps.changeBtnNewDel(true);
              resetMD5();
            });
          }}>
          <span>Удаленные</span>
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = {
  changeBtnNewDel,
  saveMD5,
  saveMD5controls,
  saveAdditionalMD5
};
const mapStateToProps = store => {
  return {
    deleted: store.leftMenu.currentTab.deleted,
    reportStatus: store.leftMenu.currentTab,
    reportsData: store.leftMenu.reportsData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDelBtn);