const defaultTemp = () => {
    return{
        emailHolder: "Почта",
        passwordHolder: "Пароль",
        fioHolder: "Имя"
    }
    
}

export default defaultTemp;
