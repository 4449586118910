import _ from 'lodash';
import md5 from "react-native-md5";

const workState = {
  reportPK: null,     //pk репорта, если он вывбран в левом меню
  templatePK: null,   //pk шаблона
  nodeId:null,        //pk конечной ноды, при работе с текстовыми заключением
  isShared:false,     //Property for shared report
  metaData: ["", "", "", ""],
  allActive: true,
  categoryArray: [
    { title: "Изображения", visible: true, subtitle: "Изображения", "necessarily": true },
    { title: "Текст заключения", visible: true, subtitle: "Заключение", "necessarily": true }
  ],
  defaultMD5_categoryArray: "da7a45e4907b8d5afa9fc8f725d0830d",
  generatedText: "",
  imageArray: [],
  tmpImageArray: [],
  reportData: [],
  md5: "",
  md5Controls: "",
  additionalMD5: "",
  imagesMD5: "",
  fieldOfStudy: ''
};

export const editorStateReducer = (state = workState, action) => {
  switch (action.type) {
    case "SET_DEFAULT_WORKSTATE":
      return {
        ...state,
        reportPK: null,     //pk репорта, если он вывбран в левом меню
        templatePK: null,   //pk шаблона
        nodeId: null,
        isShared:false,
        metaData: ["", "", "", ""],
        allActive: true,
        categoryArray: [
          { title: "Изображения", visible: true, subtitle: "Изображения", "necessarily": true },
          { title: "Текст заключения", visible: true, subtitle: "Заключение", "necessarily": true }
        ],
        defaultMD5_categoryArray: "da7a45e4907b8d5afa9fc8f725d0830d",
        generatedText: "",
        imageArray: [],
        tmpImageArray: [],
        reportData: [],
        md5: "",
        md5Controls: "",
        additionalMD5: ""
      };
    case "SET_CATEGORY_ARRAY":
      let tmp = state.categoryArray;
      //tmp.forEach((data, index) => {
      //debugger;
      //if (data.pk != undefined) {
      //delete tmp[index];
      //console.log(index);
      _.remove(tmp, function (item) {
        return item.pk !== undefined;
      });
      //}
      //console.log(data);
      //});
      return {
        ...state//,
        //categoryArray: action.categoryArray
      };
    case "ON_CREATE_NEW_REPORT":
      state.reportData = [];
      state.categoryArray.splice(0, state.categoryArray.length - 2);
      return {
        ...state,
        reportPK: null
      };
    case "SET_NODE_ID":
      return {
        ...state,
        nodeId: action.payload.nodeId
      };
    case "ON_SELECT_END_NODE":
      let today = new Date();
      return {
        ...state,
        imageArray: [],
        isShared: null,
        templatePK: action.payloadNodeType === 1 ? action.payloadNodePK : null,
        metaData: [`${today.getFullYear()}-${("0" + (today.getMonth() + 1).toString()).slice(-2)}-${("0" + (today.getDate().toString())).slice(-2)}`, "", "", ""],
      };
    case "SET_START_TEXT":
      return {
        ...state,
        generatedText: action.payloadStartText,
        md5: action.payloadStartText ? md5.hex_md5(action.payloadStartText) : ""
      };
    case "ON_LOAD_REPORT":
      state.reportData = [action.payloadReportData];
      state.reportPK = null;
      return {
        ...state,
        isShared: action.payloadReportData.isShared ? true : false, 
        reportPK: action.payloadReportData.pk,
        generatedText: action.payloadReportData.text ? action.payloadReportData.text : "",
        templatePK: action.payloadReportData.template ? action.payloadReportData.template : null,//In test
        nodeId: action.payloadReportData.nodeId ? action.payloadReportData.nodeId : null,//In test
        metaData: [action.payloadReportData.date, action.payloadReportData.patient_name, action.payloadReportData.patient_date_of_birth],
        imageArray: [],
        fieldOfStudy: action.payloadReportData.examination_area.area
      };
    case "PARSE_DATA":
      state.categoryArray.splice(0, state.categoryArray.length - 2);
      state.allActive = true;
      state.categoryArray.forEach(function (item) {
        if (item["necessarily"] !== true) {
          item["visible"] = false;
        }
      });
      parseStructure(action.payloadData);
      if (state.reportData.length > 0) {
        let senten = state.reportData[0].sentences;
        let tmp = state.categoryArray.slice(0, state.categoryArray.length - 2);
        tmp.map((levelOne) => {
          return levelOne.paragraph.map((levelTwo) => {
            let tmp = senten.filter(element => element.template === levelTwo.pk);
            if (tmp !== undefined) {
              return levelTwo.matrixArray = tmp;
            }
          });
        });
      }
      else {
        let tmp = state.categoryArray.slice(0, state.categoryArray.length - 2);
        tmp.map((column) => {
          return column.paragraph.map((paragraph) => {
            if (paragraph.type === "R") {
              return paragraph.matrixArray.push(paragraph.matrix);
            }
          });
        });
      }
      return {
        ...state,
        templatePK: action.payloadData["pk"]
      };
    //}
    case "SET_PK":
      return {
        ...state,
        reportPK: action.payloadReportPK
      };
    case "ADD_OBJECTS":
      let path = state.categoryArray[action.payloadFirstIndex].paragraph[action.payloadSecondIndex];
      path.matrixArray.push(JSON.parse(JSON.stringify({ ...path.matrix, status: "A" })));
      return {
        ...state
      };
    case "DELETE_OBJECTS":
      let paths = state.categoryArray[action.payloadFirstIndex].paragraph[action.payloadSecondIndex];
      if (paths.matrixArray[action.payloadDeletedIndex].status === "N") {
        paths.matrixArray[action.payloadDeletedIndex].status = "R";
      }
      else { paths.matrixArray.splice(action.payloadDeletedIndex, 1); }
      return {
        ...state,
        categoryArray: state.categoryArray
      };
    case "CHANGE_TEXT_CONTROLS":
      {
        let pathss = state.categoryArray[action.payloadFirstIndex].paragraph[action.payloadSecondIndex];
        pathss.matrixArray[action.payloadThirdIndex].values[action.payloadFouthIndex].text = action.payloadText;
        return {
          ...state
        };
      }
    case "CHANGE_RADIO":
      {
        let pathss = state.categoryArray[action.payloadFirstIndex].paragraph[action.payloadSecondIndex];
        pathss.matrixArray[action.payloadThirdIndex].values[action.payloadFouthIndex].selected_choice = action.payloadChoice;
        return {
          ...state
        };
      }

    case "CHANGE_CHECK":
      {
        let pathss = state.categoryArray[action.payloadFirstIndex].paragraph[action.payloadSecondIndex];
        let arr = pathss.matrixArray[action.payloadThirdIndex].values[action.payloadFouthIndex].selected_choices;
        let index = arr.indexOf(action.payloadCheck);
        if (index >= 0) {
          arr.splice(index, 1);
        } else {
          arr.push(action.payloadCheck);
        }
        return {
          ...state
        };
      }

    case "UPDATE_GENERATED_SENTENCE_TEXT": {
      let pathss = state.categoryArray[action.payloadFirstIndex].paragraph[action.payloadSecondIndex];
      pathss.matrixArray[action.payloadThirdIndex].generatedText = action.payloadGeneratedSentence;

      return {
        ...state
      };
    }

    case "SET_ACTIVE_CATEGORY":
      if(state.allActive){
        state.categoryArray.map((category)=>{
          return category.visible = false
        })
      }
      state.categoryArray[action.payloadIndexCategory].visible = state.categoryArray[action.payloadIndexCategory].visible ? false : true;
      return {
        ...state,
        allActive: state.categoryArray.every((element) => { return element.visible === true; })
      };
    case "SET_ACTIVE_ALL":
      let n = 0;
      while (state.categoryArray.length > n) {
        state.categoryArray[n].visible = state.allActive ? false : true;
        n++;
      }
      return {
        ...state,
        allActive: !state.allActive
      };
    case "CHANGE_METADATA":
      state.metaData[action.payloadIndex] = action.payloadText;
      return {
        ...state,
        metaData: state.metaData
      };
    case "CHANGE_TEXT":
      return {
        ...state,
        generatedText: action.payloadReportText
      };
    case "SET_IMAGES_ARRAY":
      return {
        ...state,
        imageArray: action.imgData
      };
    case "SET_TMP_IMAGES_ARRAY":
      return {
        ...state,
        tmpImageArray: action.tmpImgData
      };
    case "SET_MD5":
      return {
        ...state,
        md5: action.md5
      };
    case "SET_MD5_CONTROLS":
      return {
        ...state,
        md5Controls: action.md5
      };
    case "SET_MD5_ADDITIONAL":
      return {
        ...state,
        additionalMD5: action.md5
      };
    case "SET_MD5_IMAGES":
      return {
        ...state,
        imagesMD5: action.md5Images
      };
    case "FIELD_OF_STUDY":
      return {
        ...state,
        fieldOfStudy: action.fieldOfStudy
      }
    default:
      return state;
  }
};
export function parseStructure(data) {
  data.paragraphs.reverse().map((paragraph) => {
    return workState.categoryArray.unshift({
      pk: paragraph.pk,
      title: `${paragraph.title}`,
      visible: true,
      paragraph: paragraph.sentences.map((sentence) => {
        return {
          ...sentence,
          matrix: {
            pk: -1,
            status: workState.reportPK ? "N" : "A",
            values: sentence.values.map((value) => {
              if (value.type === 2 || value.type === 3) {
                return {
                  text: "",
                  type: value.type,
                  template: value.pk,
                  pk: -1
                };
              }
              if (value.type === 1) {
                return {
                  selected_choice: value.default_choice,
                  type: value.type,
                  template: value.pk,
                  pk: -1
                };
              }
              else {
                return {
                  selected_choices: [],
                  type: value.type,
                  template: value.pk,
                  pk: -1
                };
              }
            }),
            template: sentence.pk

          },
          matrixArray: []
        };
      })
    });
  });
}