const workState = {
   selectedTabIndex: 1,
   rightPanel: '',
   popupData: []
}
const arrays = {
   categoryArray: [],
   currentColumn: 0,
   currentRowsArray: [],
   fieldOfStudy: ''
}

export const workStateReducers = (state = workState, action) => {
   switch (action.type) {
      case "POPUP_EXPERIMENT":
         let tmpArray = [];
         //debugger;
         for (var i in workState.popupData) {
            if (workState.popupData[i].text[0] !== action.serchText) {
               //debugger;
               tmpArray.push(workState.popupData[i]);
            }
         }
         //debugger;
         return {
            ...state,
            popupData: tmpArray
         }
      case "CHANGE_POPUP_DATA":
         //debugger;
         return {
            ...state,
            popupData: action.popupData
         }
      default:
         return state;
   }
}
export const arraysReducers = (state = arrays, action) => {
   switch (action.type) {
      case "ON_CREATE_NEW_REPORT":
         state.categoryArray.splice(0, state.categoryArray.length);
         return {
            ...state,
            categoryArray: [],
            currentRowsArray: []
         }
      case "SET_FIELD_OF_STUDY":
         return {
            ...state,
            fieldOfStudy: action.fieldOfStudy
         }
      case "ADD_ARRAY_CATEGORY":
         let arrayLenth = state.categoryArray.length;
         if (action.payloadSelected !== null) {
            state.currentRowsArray[action.payloadLevel] = action.payloadSelected;
         }
         if (action.payloadLevel + 1 !== arrayLenth && arrayLenth !== 0) {
            state.categoryArray.splice(action.payloadLevel + 1, arrayLenth);
            state.currentRowsArray.splice(action.payloadLevel + 1, arrayLenth);

         }
         if (!action.payloadArray) {
            return {
               ...state,
               categoryArray: [...state.categoryArray],
               currentColumn: action.payloadLevel
            }
         }
         return {
            ...state,
            categoryArray: [...state.categoryArray, action.payloadArray],
            currentColumn: action.payloadLevel,

         }
      default:
         return state;
   }
}

