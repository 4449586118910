import React from "react";
import "./css/changePassword.css";
import $ from "jquery";
import store from "../../index";
import { createPopupReportList } from "../../store/editorState/actions";

export default class changePassword extends React.Component {

    newPassword = "";

    checkPasswords() {
        let inputs = $('[popupType = "resetPassword"] input');
        let newPassword = inputs[0].value;
        let newPasswordDouble = inputs[1].value;
        if (!newPassword || !newPasswordDouble) {
            return createPopupReportList(store,
                ["Не все поля заполнены"],
                "O", {});
        }
        if (newPassword !== newPasswordDouble) {
            createPopupReportList(store,
                ["Введенные пароли не совпадают"],
                "O", {});
        }
        else {
            let obj = this;
            obj.newPassword = newPassword;
            this["props"].data.buttonEvent["sAs"](obj.newPassword);
            this.defaultButtonEvent();
        }
    }

    defaultButtonEvent() {
        let popupData = store.getState().workState.popupData;
        let tmp = this;
        let tmpArray = [];
        popupData.forEach(function (data) {
            if (data.popupType !== "changePassword") {
                tmpArray.push(data);
            }
        });
        store.dispatch({
            type: "CHANGE_POPUP_DATA",
            popupData: tmpArray
        });
    }

    render() {
        //console.log(this["props"].data);
        return (
            <div popupType="resetPassword" className="popupBody">
                <div>
                    <span>Новый пароль</span><br />
                    <input placeholder='***' type="password"></input>
                </div>
                <div>
                    <span>Новый пароль еще раз</span><br />
                    <input placeholder='***' type="password"></input>
                </div>
                <div className="buttonContainer">
                    <div onClick={() => { this.defaultButtonEvent(); }}>Отмена</div>
                    <div onClick={() => { this.checkPasswords() }}>Изменить пароль</div>
                </div>
            </div>
        );
    }
}