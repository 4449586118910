import React, { useState, useEffect } from "react";
import store from "../../../../index";
import { imageSwitcher } from "../images/images";
import $ from "jquery";
import ReactTooltip from 'react-tooltip';

export default function NodeColumn (props) {

  const [rowIndex,setRowIndex] = useState(null);

  const tips = new Map();

  //Effect: -clear index when user do step back
  useEffect(() => {
    ReactTooltip.rebuild();
    if (rowIndex !== null) {
      setRowIndex(null)
    }
    $(".categoryList").scrollLeft(8000);
  }, [props.data])

  function clickOtherNodes(column) {
    
    switch (column.type) {
      case 0:
        props.getData(column.pk, props.nodeIndex);
        break;
      case 2:
      case 3:
        props.startText(column.pk, column.type === 3);
      default:
        props.endNode(column.pk,column.type);
        break;
    }
    
    if(props.nodeIndex === 1 && column.title !== undefined){
      store.dispatch({
        type: "FIELD_OF_STUDY",
        fieldOfStudy: column.title
      });
    }
  }

  if (props.main) {
      return (
        <div className="nodeContainer">
        <div className="mainNode">
          {props.data.map((column, index) => {
            return (
              <div key={index} title={column.title}
                className={`mainCategory ${rowIndex === index && "nodeSelected"}`}
                onClick={() => {
                  setRowIndex(index)
                  props.getData(column.pk, 0, index);
                }}>
                {column.hasTemplatesInside ? <img alt="logo" className="hasTemplatesInsideBaseNode" src="/icons/hasTemplatesInsideK.svg" /> : ""}
                <div>
                  <img src={imageSwitcher(column.icon)} className="mainCategoryImg" alt={column.title} />
                </div>
                <div>
                  <span>{column.title}</span>
                </div>
              </div>
            );
          })}
        </div></div>);
    }
    else {
      return (
          <div className="nodeContainer">
            <div className="otherNodes">
              <ReactTooltip id="tt" getContent={(pk) => pk ? startTextTip(pk, true) : ""}
                            className="tooltip" type="info" place="left" effect="solid" />
              {
                props.data.map((column, index) => {
                  return (
                      <div key={index} title={column.title}
                           className={`${rowIndex === index && "nodeSelected"}`}
                           onClick={() => {
                             setRowIndex(index)
                             clickOtherNodes(column, index);
                           }}
                           data-tip={column.type === 3 ? column.pk : ""} data-for="tt">
                        {column.hasTemplatesInside ?
                            <img alt="logo" className="hasTemplatesInside" src="/icons/hasTemplatesInsideK.svg"/> : ""}
                        <span className={(column.hasTemplatesInside ? "nodeNameWithTemplate" : "") + (column.type !== 0 ? " nodeLeaf" : "")}>
                          {column.title}
                        </span>
                      </div>
                  );
                })}
            </div>
          </div>
        );
    }

    function startTextTip (reportPk, isReportPk) {
      if (tips.has(reportPk)) return tips.get(reportPk);

      var queryParam = "?" + (isReportPk ? "report_pk=" : "node_pk=") + reportPk;
      var tooltip = "not loaded";
      $.ajax({
        type: "GET",
        url: `${store.getState().settings.url}/examinations/reports/report_start_text${queryParam}`,
        contentType: 'application/json',
        crossDomain: true,
        headers: {
          'Authorization': `Token ${store.getState().settings.userKey}`
        },
        async: false,
        success: function (data) {
          tooltip = data.data;
          tips.set(reportPk, tooltip);
        }
      });

      return tooltip;
  }
}