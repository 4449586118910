import React, { useEffect,useState } from "react";
import $ from "jquery";
import "./leftMenu.css";
import ReportsList from "./ReportsList";
import NewReport from "./newReport";
import NewDelBtn from "./newDelBtnl";
import TabsPanel from "./tabsPanel";
import { connect } from "react-redux";
import { setTabsData, setReportsData } from "../../../../store/leftMenu/actions";
import SearchComponent from "./searchComponent";
import store from "../../../../index";
import { createPopupReportList } from "../../../../store/editorState/actions";


function LeftMenu(props) {
  const [isLoad,startLoad] = useState(false)
  useEffect(() => {
    getTabsData();
  }, []);

  function getTabsData() {
    $.ajax({
      type: "GET",
      url: `${props.url}/examinations/navigation/root/`,
      contentType: "application/json",
      headers: {
        "Authorization": `Token ${store.getState().settings.userKey}`
      },
      success: function (data) {
        props.setTabsData(data);
      },
      error: function (data) {
        if (data.status === 401) {
          createPopupReportList(store,
            ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
            "O", {
            "O": () => {
              document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.location.reload(true);
            }
          });
        }
        else {
          createPopupReportList(store,
            ["Не удалось получить типы исследований!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте позднее."],
            "O", {});
        }
      }
    });
  }

  return (
    <div className="leftMenuContainer">
      <TabsPanel />
      <SearchComponent startLoad={startLoad}/>
      <NewReport />
      <ReportsList isLoad={isLoad} startLoad={startLoad}/>
      <NewDelBtn />
    </div>
  );
}

const mapDispatchToProps = {
  setTabsData,
  setReportsData
};
const mapStateToProps = store => {
  return {
    url: store.settings.url,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);