import React, { Component } from "react";
import "./workSpace.css";
import TopPanel from "./topPanel/topPanel";
import WorkPanel from "./workPanel/workPanel";
import NoPayments from "./workPanel/noPayments";
import SettingsPanel from "./settingsPanel/settingsPanel";
import { connect } from "react-redux";
import PopupContainer from "../popups/popupContainer";
import store from "../../index";

class WorkSpace extends Component {

  changePanels() {
    this.setState({ currentPanel: this.state.currentPanel === "workPanel" ? "settingsPanel" : "workPanel" });
  }

  switcherPanels() {
    //switch (this.state.currentPanel) {
    switch (store.getState().settings.currentPanel) {
      case "workPanel":
        return <WorkPanel settings={this["props"].settings} />;
      case "noPayments":
        return <NoPayments />;
      case "settingsPanel":
        return <SettingsPanel changePanels={() => { this.changePanels(); }} />;
      default:
        return <WorkPanel />;
    }
  }

  render() {
    return (
      <div className="workSpace">
        <TopPanel userFio={this["props"].settings.userFio} changePanels={() => { this.changePanels(); }} />
        {this.switcherPanels()}
        <div className="containerForPopup">
          <div id="bgPopup" onClick={(e) => {
            store.dispatch({
              type: "CHANGE_POPUP_DATA",
              popupData: []
            })
          }
          }></div>
          <PopupContainer />
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settings
  };

};

export default connect(mapStateToProps)(WorkSpace);