import React from "react";
import { connect } from "react-redux";
import { workIcons } from "../images/images";
import "./reportEditor.css";
import $ from "jquery";
import store from "../../../../index";
import workWithServer from "./workWithServer";
import _ from 'lodash';
import "../../../popups/css/loader.css";
import GlobalSettings from "../../../../settings/GlobalSettings";

class InsertImgBtn extends React.Component {

  imgData = [];
  serverImage = {};
  loadStatus = false;
  reader = [];

  selectImg() {
    var tmp = this;
    var file = document.getElementById("selecterImg").files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      if (store.getState().editorState.isShared !== true) {
        tmp.imgData = store.getState().editorState.imageArray;
        let tmpObj = {};
        let currentTime = new Date().getTime();
        tmpObj[currentTime] = reader.result;
        tmp.imgData.push(tmpObj);
        store.dispatch({
          type: "SET_IMAGES_ARRAY",
          imgData: tmp.imgData
        });

        //if (store.getState().leftMenu.selectedReport != null) {
        tmp.serverImage.WWS.changeReportStatus("edite");
        tmp.serverImage.WWS.changeData(currentTime, tmpObj[currentTime], "add");
        tmp.serverImage.saveImages(store.getState().editorState.imageArray);
        //}
      }
      else {
        let tempPopupData = store.getState().workState.popupData;
        tempPopupData.push({
          buttonType: "O",
          text: ["В образец заключения запрещено добавлять изображения !"],
          popupType: "simple",
          headColor: "warning",
          buttonEvent: {}
        });
        store.dispatch({
          type: "CHANGE_POPUP_DATA",
          popupData: tempPopupData
        });
      }
    };

    if (file) {
      if (file.type.indexOf("image") !== -1) {
        reader.readAsDataURL(file);
      }
      else {
        let tempPopupData = store.getState().workState.popupData;
        tempPopupData.push({
          buttonType: "O",
          text: ["Выбранный обьект не является изображением"],
          popupType: "simple",
          headColor: "warning",
          buttonEvent: {
            "O2": this.popupButton
          }
        });
        store.dispatch({
          type: "CHANGE_POPUP_DATA",
          popupData: tempPopupData
        });
      }
    }
    else {
      //console.log("Отмена действия: выбор изображения");
    }
  }

  deleteFromRedux(id) {
    let tmpArray = [];
    this.imgData = store.getState().editorState.imageArray;
    this.imgData.forEach(function (data) {
      if (Object.keys(data)[0] !== id) {
        tmpArray.push(data);
      }
    });
    this.imgData = tmpArray;
    store.dispatch({
      type: "SET_IMAGES_ARRAY",
      imgData: this.imgData
    });
  }

  onDeleteButton(id) {
    if (id.indexOf("_fromServer") !== -1) {
      //Удалить с сервера, из редукса и из хранилища
      let obj = this;
      const imgId = id.substr(0, id.indexOf("_fromServer"));

      this.serverImage.WWS.changeReportStatus("edite");
      this.serverImage.WWS.changeData(imgId, _.find(store.getState().editorState.imageArray, id)[id], "delete");

      //store.dispatch({
      //  type: "CHANGE_POPUP_DATA",
      //  popupData: [{ text: ["preloader_Удаление изображения"] }]
      //});

      $.ajax({
        type: "DELETE",
        url: GlobalSettings.apiUrl + imgId,
        headers: {
          "Authorization": `Token ${store.getState().settings.userKey}`
        },
        success: function (data) {
          obj.deleteFromRedux(id);
          //store.dispatch({
          //  type: "POPUP_EXPERIMENT",
          //  serchText: "preloader_Удаление изображения"
          //});
          //obj.serverImage.WWS.changeReportStatus("ready");
          //debugger;
          //obj.serverImage.WWS.deleteImage(imgId);
        },
        error: function () { }
      });
    }
    else {
      let tmpData = store.getState().editorState.imageArray;
      //debugger;
      this.serverImage.WWS.changeData(id, _.find(tmpData, id)[id], "delete");
      //Удалить из редукса
      this.deleteFromRedux(id);
    }
  }

  deleteImg(id) {
    let tempPopupData = store.getState().workState.popupData;
    tempPopupData.push({
      buttonType: "C_D",
      text: ["Удалить изображение ?"],
      popupType: "simple",
      headColor: "warning",
      buttonEvent: {
        "D": () => { this.onDeleteButton(id); }
      }
    });
    store.dispatch({
      type: "CHANGE_POPUP_DATA",
      popupData: tempPopupData
    });
  }

  createImage() {
    return store.getState().editorState.imageArray.map((result, index) => {
      return (
        <div key={"imageContainer_" + index} className="imageContainer">
          {store.getState().editorState.isShared === false &&
            <div onClick={() => { this.deleteImg(Object.keys(result)[0]); }} title="Удалить изображение">{workIcons("deleteImgIcon")}</div>
          }
          <img onClick={() => { this["props"].showGallery(index); }} src={result[Object.keys(result)[0]]} alt="" />
        </div>
      );
    });
  }

  overStatus = false;

  dragOver(ev) {
    //console.log(document.getElementsByClassName('imagesContainer').length);
    if ($(".imagesContainer").length > 0) {
      $(".imagesContainer").attr("class", "imagesContainerOn");
      $(".imagesContainer").css("backgroundColor", "#F4F4F4");
    }
    ev.preventDefault();
  }

  dragOut(ev) {
    if ($(".imagesContainerOn").length > 0) {
      $(".imagesContainerOn").attr("class", "imagesContainer");
      $(".imagesContainer").css("backgroundColor", "");
    }
    ev.preventDefault();
  }

  dragDrop = () => {
    let ev = window.event;
    let tmp = this;
    tmp.imgData = [];
    if (store.getState().editorState.isShared !== true) {
      if (ev.dataTransfer.files.length > 0) {
        for (var i = 0; i < ev.dataTransfer.files.length; ++i) {
          //debugger;
          if (ev.dataTransfer.files[i].type.indexOf("image") !== -1) {
            //debugger;
            tmp.reader[i] = new FileReader();
            tmp.reader[i].index = i;
            tmp.reader[i].onload = (function () {
              return function () {
                var tmpObj = {};
                //tmp.imgData = store.getState().editorState.imageArray;
                let currentTime = new Date().getTime();
                //debugger;
                if (this.result !== "") {
                  tmpObj[currentTime] = this.result;
                  tmp.imgData.push(tmpObj);
                  //debugger;
                  tmp.serverImage.WWS.changeReportStatus("edite");
                  tmp.serverImage.WWS.changeData(currentTime, tmpObj[currentTime], "add");
                  store.dispatch({
                    type: "SET_IMAGES_ARRAY",
                    imgData: tmp.imgData
                  });
                  if (store.getState().leftMenu.selectedReport != null) {
                    //debugger;
                    if (this.index === (tmp.reader.length - 1)) {
                      tmp.serverImage.saveImages(tmp.imgData, true);
                    }
                    else {
                      tmp.serverImage.saveImages(tmp.imgData, false);
                    }
                    tmp.imgData = [];
                  }
                }
              };
            })();
            tmp.reader[i].readAsDataURL(ev.dataTransfer.files[i]);
            $(".imagesContainerOn").attr("class", "imagesContainer");
          }
          else {
            let tempPopupData = store.getState().workState.popupData;
            tempPopupData.push({
              buttonType: "O",
              text: ["Один из перемещаемых обьектов не является изображением"],
              popupType: "simple",
              headColor: "warning",
              buttonEvent: {}
            });
            store.dispatch({
              type: "CHANGE_POPUP_DATA",
              popupData: tempPopupData
            });
          }
        }
      }
    }
    else {
      let tempPopupData = store.getState().workState.popupData;
      tempPopupData.push({
        buttonType: "O",
        text: ["В образец заключения запрещено добавлять изображения !"],
        popupType: "simple",
        headColor: "warning",
        buttonEvent: {}
      });
      store.dispatch({
        type: "CHANGE_POPUP_DATA",
        popupData: tempPopupData
      });
      $(".imagesContainerOn").attr("class", "imagesContainer");
    }
    $(ev.target).css("backgroundColor", "");
    ev.preventDefault();
    window.event.preventDefault();
    return false;
  }

  dragEnter(ev) {
    ev.preventDefault();
    return true;
  }

  componentDidMount() {
    //this.serverImage.WWS.dataAnaliz(this);
    //if (store.getState().leftMenu.selectedReport != null) {
    //  this.serverImage.getImagesIdFromServer("onLoad");
    //}
  }

  render() {
    let obj = this;
    obj.loadStatus = true;
    this.serverImage = new workWithServer(this);

    return (
      <div onDrop={this.dragDrop} onDragLeave={(event) => { return this.dragOut(event); }} onDragOver={(event) => { return this.dragOver(event); }} className="mainImagesContainer">
        { store.getState().editorState.isShared === false &&
        <div>
          <div className="imageUploadButtons" onClick={() => { $("#selecterImg").click(); }}>
            {workIcons("uploadFromPC")}
            <span title="Перетащить изображение">Загрузить с ПК</span>
            <input id="selecterImg" type="file" onChange={() => { this.selectImg(); }}></input>
          </div>
          <span className="tipForImageArea">или перетащите изображение сюда</span>
        </div>
        }
        <div className="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="preloaderText"></div>
        </div>
        <div className="imagesContainer">
          {this.createImage()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    imageArray: state.editorState
  };
};
export default connect(mapStateToProps)(InsertImgBtn);