import React from "react";
import "./workPanel.css";
import store from "../../../index";

export default function NoPayments() {
    return (
        <div className="workPanel modWorkPanel">
            <div>Чтобы пользоваться системой, оформите</div>
            <div>подписку в <span onClick={() => {
                store.dispatch({
                    type: "SET_SETTINGSPANEL"
                });
            }}>личном кабинете</span>.</div>
        </div>
    );
}