import React from "react";
import $ from "jquery";
import { createPopupReportList, setToCurrentMD5 } from "../../../../store/editorState/actions";
import "./reportEditor.css";
import store from "../../../../index";
import md5 from "react-native-md5";
import workWithServer from "./workWithServer";

class checkModReport {
  pk = -1;
  templatePK = -1;
  tmpCtg = {};
  t = {};
  serverImage = new workWithServer();

  resetMD5 = () => {
    let res = store.getState().editorState.categoryArray.map(a => ({ ...a }));
    let tmpCategoryArray = {};
    res.map(function (data, index) {
      delete (data.visible);
      return tmpCategoryArray[index] = data;
    });

    store.dispatch({
      type: "SET_MD5_IMAGES",
      md5Images: md5.hex_md5(JSON.stringify(store.getState().editorState.imageArray))
    });
    store.dispatch({
      type: "SET_MD5_CONTROLS",
      md5: md5.hex_md5(JSON.stringify(tmpCategoryArray))
    });
    if (store.getState().editorState.generatedText !== "") {
      store.dispatch({
        type: "SET_MD5",
        md5: md5.hex_md5(store.getState().editorState.generatedText)
      });
    }
    if (store.getState().editorState.metaData[0] !== "") {
      store.dispatch({
        type: "SET_MD5_ADDITIONAL",
        md5: md5.hex_md5(md5.hex_md5(store.getState().editorState.metaData[0] + store.getState().editorState.metaData[1] + store.getState().editorState.metaData[2]))
      });
    }
  }

  // Method for create object which will be send to save on server. Support two types of reports: text and template
  // Arguments: - text: a report text
  createReportObject = (text) => {
    let tmpObject = {
      pk: this.tmpCtg.reportPK ? this.tmpCtg.reportPK : -1,
      date: this.tmpCtg.metaData[0],
      patient_date_of_birth: this.tmpCtg.metaData[2],
      patient_name: this.tmpCtg.metaData[1],
      text: text ? text : ""
    }
    if (!this.tmpCtg.templatePK) {
      tmpObject["nodeId"] = this.tmpCtg.nodeId;
    }
    else {
      tmpObject["sentences"] = this.t.flat(2);
      tmpObject["template"] = this.tmpCtg.templatePK;
    }
    return tmpObject
  }

  sendReport = (text, addFunct, saveAndCopy = false) => {
    let tmpObj = this.createReportObject(text);
    let obj = this;
    $.ajax({
      type: `${this.tmpCtg.reportPK ? "PUT" : "POST"}`,
      url: `${store.getState().settings.url}/examinations/reports/${this.tmpCtg.reportPK ? this.tmpCtg.reportPK : ""}`,
      contentType: "application/json",
      data: `${JSON.stringify(tmpObj)}`,
      headers: {
        "Authorization": `Token ${store.getState().settings.userKey}`
      },
      crossDomain: true,
      success: function (data) {
        if (!saveAndCopy) {
          createPopupReportList(store,
            ["Заключение сохранено"],
            "O", {}, "sucsess");
        }
        setToCurrentMD5();
        if (store.getState().editorState.reportPK === null) {
          tmpObj.serverImage = new workWithServer(tmpObj);
          tmpObj.serverImage.WWS.dataAnaliz(tmpObj, data.pk);
        }
        obj.serverImage.WWS.onSelectReport();
        addFunct(data.pk);
      },
      error: function (data) {
        if (data.status === 401) {
          createPopupReportList(store,
            ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
            "O", {
            "O": () => {
              document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.location.reload(true);
            }
          });
        }
        else {
          createPopupReportList(store,
            ["Не удалось сохранить заключение!", <br />, "Пожалуйста, попробуйте позднее."],
            "O", {});
        }
      }
    });
  }

  checkAdditionalData = () => {
    let res = store.getState().editorState.categoryArray.map(a => ({ ...a }));
    let tmpCategoryArray = {};
    res.map(function (data, index) {
      delete (data.visible);
      return tmpCategoryArray[index] = data;
    });

    if ($(".reportEditorContainer").length === 0) {
      return true;
    }
    if ((store.getState().editorState.metaData[0] === "")) {
      return false;
    }
    else {
      return true;
    }
  }

  onCancelSaveImages(accidentally) {
    //Отправляем запросы на сервер об удалении/сохранении изображений
    tmpObj.serverImage.WWS.changeReportStatus("edite");
    tmpObj.serverImage.WWS.dataAnaliz(tmpObj);
    tmpObj.serverImage.WWS.changeReportStatus("ready");
    //Возвращаем редукс в исходное состояние
    //debugger;
    if (accidentally !== true) {
      tmpObj.serverImage.getImagesIdFromServer();
    }
    tmpObj.serverImage.WWS.onSelectReport();
  }

  checkMod = () => {
    if (this.checkAdditionalData() === true) {
      this.tmpCtg = store.getState().editorState;
      let tmp = this.tmpCtg.categoryArray.slice(0, this.tmpCtg.categoryArray.length - 2);
      this.t = tmp.map((levelOne) => {
        return levelOne.paragraph.map((levelTwo) => {
          return levelTwo.matrixArray;
        });
      });
      const tmpText = store.getState().editorState.generatedText;

      const startMD5text = store.getState().editorState.md5;
      let endMD5text = "";
      store.getState().editorState.generatedText !== "" ? endMD5text = md5.hex_md5(store.getState().editorState.generatedText) : endMD5text = "";

      let res = store.getState().editorState.categoryArray.map(a => ({ ...a }));
      let tmpCategoryArray = {};
      res.map(function (data, index) {
        delete (data.visible);
        return tmpCategoryArray[index] = data;
      });
      const startMD5controls = store.getState().editorState.md5Controls;
      let endMD5controls = "";
      md5.hex_md5(JSON.stringify(tmpCategoryArray)) === store.getState().editorState.defaultMD5_categoryArray ? endMD5controls = "" : endMD5controls = md5.hex_md5(JSON.stringify(tmpCategoryArray));
      if (startMD5controls === "" && endMD5controls === store.getState().editorState.defaultMD5_categoryArray) {
        endMD5controls = "";
      }
      if (startMD5controls === store.getState().editorState.defaultMD5_categoryArray && endMD5controls === "") {
        endMD5controls = store.getState().editorState.defaultMD5_categoryArray;
      }
      //debugger;
      //console.log("КОНТРОЛЫ --> Собранный MD5: " + endMD5controls + ", записанный MD5: " + startMD5controls);
      //console.log("ТЕКСТ --> Собранный MD5: " + endMD5text + ", записанный MD5: " + startMD5text);
      if (startMD5controls !== endMD5controls) {
        if (startMD5text !== endMD5text) {
          //заключение изменено с помощью контролов и одновременно с помощью ручного ввода текста: отмена - сгенерировать - сохранить
          return -2;
        }
        else {
          //заключение изменено с помощью контролов: отмена - сгенерировать
          if (tmpText === "" && store.getState().editorState.reportPK === null) {
            return 1.1;
          }
          else {
            return 1;
          }
        }
      }
      else {
        if (startMD5text !== endMD5text) {
          //заключение изменено с помощью ручного ввода текста: отмена - сохранить
          return 2;
        }
        else {
          //Единственное место, где необходимо проверять ОБЯЗАТЕЛЬНЫЕ данные на изменения
          if ($(".reportEditorContainer").length === 0) {
            //Выбрали заключение - еще нет reportEditor - не проводим никаких проверок
            return false;
          }
          else {
            if (store.getState().editorState.reportPK === null) {
              // hack for shared text reports if text hasn't been changed
              if (startMD5text === endMD5text) return false;

              //Если это вновь созданный репорт
              return 3;
            }
            else {
              if (store.getState().editorState.additionalMD5 !== md5.hex_md5(store.getState().editorState.metaData[0] + store.getState().editorState.metaData[1] + store.getState().editorState.metaData[2])) {
                //Были изменения в обязательных данных
                return 3;
              }
              else {
                //Если изменений нет
                if (this.serverImage.WWS.didChange() === true) {
                  //debugger;
                  //Изменения по картинкам
                  return 4;
                }
                else {
                  return false;
                }
                //this.checkImages(1);
              }
            }
          }
        }
      }
    }
    else {
      //Не введены обязательные данные
      return -1;
    }
  }

  check = (addFunct, accidentally = true, checkSave = false, saveAndCopy = false) => {
    //debugger;
    let checkType = this.checkMod();
    const tmpText = store.getState().editorState.generatedText;
    //debugger;
    if (accidentally === true && checkType !== false) {
      //Случайное нажатие
      createPopupReportList(store,
        ["Заключение было изменено.", <br />, "Сохранить изменения?"],
        "C_Ns_S", {
        "C": () => { },
        "Ns": function () {
          tmpObj.onCancelSaveImages(accidentally);
          setToCurrentMD5();
          addFunct();
        },
        "S": function () {
          tmpObj.check(addFunct, false);
          if (store.getState().editorState.reportPK != null) {
            tmpObj.serverImage.WWS.onSelectReport();
          }
        }
      });
    }
    else {
      //debugger;
      if (checkType !== false) {
        switch (checkType) {
          default: {
            createPopupReportList(store,
              ["Текст заключения был изменен вручную.", <br />, "Сохранить измененный текст или сгенерировать заново?"],
              "C_G_S", {
              "C": () => { },
              "G": function () {
                tmpObj.sendReport("", addFunct, saveAndCopy);
              },
              "S": () => {
                tmpObj.sendReport(tmpText, addFunct, saveAndCopy);
              }
            });
            break;
          }
          case 2: {
            if (store.getState().editorState.templatePK === null) {
              tmpObj.sendReport(tmpText, addFunct, saveAndCopy);
            }
            else {
              createPopupReportList(store,
                ["Текст заключения был изменен вручную.", <br />, "Сохранить измененный текст или сгенерировать заново?"],
                "C_G_S", {
                "C": () => { },
                "G": function () {
                  tmpObj.sendReport("", addFunct, saveAndCopy);
                },
                "S": () => {
                  if (tmpText !== "") {
                    tmpObj.sendReport(tmpText, addFunct);
                  }
                  else {
                    createPopupReportList(store,
                      ["Для сохранения репорта необходимо ввести текст заключения вручную"],
                      "O", {});
                  }
                }
              });
            }
            break;
          }
          case -1: {
            createPopupReportList(store,
              ["Не указана дата исследования"],
              "O", {
              "O": function () { }
            });
            break;
          }
          case 1: {
            createPopupReportList(store,
              ["Заключение изменено с помощью шаблона.", <br />, "Сохранить текущий текст или сгенерировать заново?"],
              "C_G_S", {
              "C": () => { },
              "G": function () {
                tmpObj.sendReport("", addFunct, saveAndCopy);
              },
              "S": () => {
                tmpObj.sendReport(tmpText, addFunct, saveAndCopy);
              }
            });
            break;
          }
          case 1.1: {
            setToCurrentMD5();
            tmpObj.sendReport("", addFunct, saveAndCopy);
            break;
          }
          case 3: {
            setToCurrentMD5();
            tmpObj.sendReport(tmpText, addFunct, saveAndCopy);
            break;
          }
          case 4: {
            tmpObj.serverImage.WWS.onSelectReport();
            createPopupReportList(store,
              ["Заключение сохранено"],
              "O", {}, "sucsess");
            addFunct();
            break;
          }
        }
      }
      else {
        console.log(saveAndCopy);
        //debugger;
        if (accidentally === true && checkType === false) {
          //Случайное нажатие + изменений нет
          addFunct();
        }
        else {
          //Изменений нет, но не случайное нажатие
          //debugger;
          if (store.getState().editorState.templatePK && !saveAndCopy) {
            createPopupReportList(store,
              ["Вы пытаетесь сохранить заключение", <br />, "Сохранить текущий текст или сгенерировать заново?"],
              "C_G_S", {
              "C": () => { },
              "G": function () {
                tmpObj.sendReport("", addFunct, saveAndCopy);
              },
              "S": () => {
                tmpObj.sendReport(tmpText, addFunct, saveAndCopy);
              }
            });
          }
          else {
            tmpObj.sendReport(tmpText, addFunct, saveAndCopy);
          }
        }
      }
    }
  }
}

const tmpObj = new checkModReport();
export default tmpObj;