import React from "react";
import "./workPanel.css";
import LeftMenu from "./leftMenu/leftMenu";
import PanelSelect from "./panelsSelect";

export default function WorkPanel() {
  return (
    <div className="workPanel">
      <LeftMenu />
      <PanelSelect />
    </div>
  );
}