import $ from "jquery";
import store from "../../../../index";
import { createPopupReportList } from "../../../../store/editorState/actions";

export default class workWithPayments {
    pay() {
        var obj = this;
        var widget = new window.cp.CloudPayments();
        var receipt = {
            Items: [//товарные позиции
                {
                    label: 'Medrep подписка', //наименование товара
                    price: store.getState().payments.price + ".00", //цена
                    quantity: 1.00, //количество
                    amount: store.getState().payments.price + ".00", //сумма
                    vat: 20, //ставка НДС
                    method: 0, // тег-1214 признак способа расчета - признак способа расчета
                    object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
                }
            ],
            email: store.getState().settings.userMail, //e-mail покупателя, если нужно отправить письмо с чеком
            phone: '', //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
            isBso: false, //чек является бланком строгой отчётности
            amounts:
            {
                electronic: store.getState().payments.price + ".00", // Сумма оплаты электронными деньгами
                advancePayment: 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                credit: 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
                provision: 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
            }
        };

        var data = {};
        data.cloudPayments = { recurrent: { interval: 'Month', period: 1, customerReceipt: receipt } }; //создание ежемесячной подписки

        widget.charge({ // options
            publicId: 'pk_c899a195c09331b7f33596c62b22b', //id из личного кабинета
            description: 'Подписка на ежемесячный доступ к сайту medrep', //назначение
            amount: store.getState().payments.price, //сумма
            currency: 'RUB', //валюта
            invoiceId: '1234567', //номер заказа  (необязательно)
            accountId: store.getState().settings.userMail, //идентификатор плательщика (обязательно для создания подписки)
            data: data
        },
            function (options) {
                createPopupReportList(store,
                    ["Подписка успешно оформлена!"],
                    "O", {
                    "O": () => { }
                },
                    "sucsess");
                obj.checkPayments();
            },
            function (reason, options) {
                //debugger;
                if (reason !== "User has cancelled") {
                    store.dispatch({
                        type: "SET_PAYMENT_DESCRIPTION",
                        payloadDescription: "Ошибка оплаты: " + reason,
                    });
                }
                createPopupReportList(store,
                    ["Ошибка проведения оплаты!"],
                    "O", {
                    "O": () => { }
                },
                    "warning");
            });
    };

    checkPayments(callBack = () => { }) {
        $.ajax({
            type: "GET",
            url: `${store.getState().settings.url}/payments/subscription`,
            contentType: "application/json",
            headers: {
                "Authorization": `Token ${store.getState().settings.userKey}`
            },
            success: (data) => {
                //debugger;
                if (data.valid !== undefined) {
                    store.dispatch({
                        type: "SET_PAYMENT_DATA",
                        payloadIcon: data.icon ? data.icon : "",
                        payloadStatus: data.status ? data.status : "",
                        payloadDescription: data.description ? data.description : "",
                        payloadLinkText: data.linkText ? data.linkText : "",
                        payloadValid: data.valid ? data.valid : "",
                        payloadPrice: data.price ? data.price : ""
                    });
                    if (data.valid === true || store.getState().settings.currentPanel === "settingsPanel") {
                        callBack();
                    }
                    else {
                        if (store.getState().settings.currentPanel === "noPayments") {
                            store.dispatch({
                                type: "SET_SETTINGSPANEL"
                            });
                        }
                        else {
                            store.dispatch({
                                type: "SET_NOPAYMENTPANEL"
                            });
                        }
                    }
                }
            }
        });
    }

    cancelPayment() {
        var obj = this;
        $.ajax({
            type: "POST",
            url: `${store.getState().settings.url}/payments/cancel_subscription`,
            contentType: "application/json",
            headers: {
                "Authorization": `Token ${store.getState().settings.userKey}`
            },
            success: (data) => {
                createPopupReportList(store,
                    ["Подписка успешно прекращена!"],
                    "O", {
                    "O": () => { }
                },
                    "sucsess");
                if (data.success === true) {
                    obj.checkPayments();
                }
                else {
                    store.dispatch({
                        type: "SET_NOPAYMENTPANEL"
                    });
                }
            },
            error: function () {
                createPopupReportList(store,
                    ["Не удалось прекратить подписку!", "Попробуйте еще раз позднее."],
                    "O", {
                    "O": () => { }
                },
                    "warning");
            }
        });
    }
}