import React, {useEffect,useState} from "react";
import leftArrow from "../images/arrowLeft.png"
import rightArrow from "../images/arrowRight.png"

export default function(props){
  const [index,setIndex] = useState(props.currentImage)
  let imageArrayLength = props.imageData.length

  useEffect(() => {
    window.addEventListener("keydown", manageGallery);
    return ()=>{
      window.removeEventListener("keydown", manageGallery);
    }
  }, [index])

  const manageGallery = (e) =>{
    switch (e.keyCode){
      case 27:
        props.close()
        return;
      case 37:
        switchImage(-1);
        return;
      case 39:
        switchImage(1)
        return;
      default:
        return
    }
  }

  const switchImage = (step) =>{
    let futureIndex = index+step
    if (futureIndex >= 0 && futureIndex < imageArrayLength) setIndex(futureIndex)
  }

  let obj = props.imageData[index];
  return (
    <div className="imageGallery" onClick={() => {props.close(); }}>
      <div>
        <img src={obj[Object.keys(obj)[0]]} alt="selected_picture" onClick={(e)=>{e.stopPropagation()}}/>
        <div onClick={() => {props.close();}}>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9154 2.10186C18.196 1.8212 18.196 1.36615 17.9154 1.0855C17.6347 0.804835 17.1797 0.804835 16.899 1.0855L1.08647 16.898C0.805812 17.1787 0.805812 17.6337 1.08647 17.9144C1.36713 18.195 1.82218 18.195 2.10284 17.9144L17.9154 2.10186Z" fill="white" />
            <path d="M2.10284 1.0855C1.82218 0.804835 1.36713 0.804835 1.08647 1.0855C0.805812 1.36616 0.805812 1.8212 1.08647 2.10186L16.899 17.9144C17.1797 18.195 17.6347 18.195 17.9154 17.9144C18.196 17.6337 18.196 17.1787 17.9154 16.898L2.10284 1.0855Z" fill="white" />
          </svg>
        </div>
      </div>
      <div className="arrow nextImg" onClick={(e)=>{e.stopPropagation();switchImage(1)}}>
        <img src={rightArrow} alt="nextImageArrow"/>
      </div>
      <div className="arrow prevImg" onClick={(e)=>{e.stopPropagation();switchImage(-1)}}>
        <img src={leftArrow} alt="previousImageArrow"/>
      </div>
    </div>
  );
}
