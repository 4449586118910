import React, { Component } from "react";
import WorkSpace from "./workSpace/workSpace";
import Auth from "./auth/auth";
import { connect } from "react-redux";
import $ from "jquery";
import store from "../index";
import { toogleFastInsert } from "../store/fastInsert/actions";

class SwitcherSpaces extends Component {

  onSwitchSpaces() {
    $("body").keyup(function (event) {
      if (event.originalEvent.keyCode === 27 && !store.getState().workState.popupData) {
        if ($(".fastInsertBody").length > 0) {
          store.dispatch({
            type: "CHANGE_SEARCH_TEXT_FI",
            payloadText: ""
          });
          store.dispatch({
            type: "TOOGLE_FAST_INSERT"
          });
        }
        if ($(".settingsPanel").length > 0) {
          store.dispatch({
            type: "CHANGE_CURRENTPANEL"
          });
        }
      }
    });
    if (this["props"].settings.userKey) {
      return <WorkSpace settings={this["props"].settings} />;
    }
    else {
      return <Auth />;
    }
  }
  render() {
    return this.onSwitchSpaces();
  }
}

const mapDispatchToProps = {
  toogleFastInsert
};

const mapStateToProps = state => {
  return {
    settings: state.settings
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitcherSpaces);