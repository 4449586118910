import React, {useState, useEffect, useRef} from "react";
import { searchIcon } from "../../images/images";
import store from "../../../../../index";
import { createPopupReportList } from "../../../../../store/editorState/actions";
import loader from "../../images/spinner.svg"
import { imageSwitcher } from "../../images/images";
import * as reportHelper from "../../../../../helpers/reportHelper";

export default function ReportList(props) {
  const [text, setText] = useState(props.text);
  const [page, setPage] = useState({ currentPage: 1, canNext: false });
  const [isLoadNext, startLoadNext] = useState(false);

  const [reportsData,setReportsData] = useState([])
  const [selectedReport,setSelectedReport] = useState(null)

  /* Поиск при изменении текста */
  /* Хур, срабатывающий при изменении поискового текста или смены таба */

  useEffect(() => {
    setReportsData([])
    props.loading(false)
    if (text.trim().length > 2 || text.trim().length === 0) {
      let controller = new AbortController();
      props.loading(true)
      const timer = setTimeout(() => {
      props.changeText(text)
        fetchFindReport(1,controller)
      }, 500);
      return () => {
        controller.abort()
        clearTimeout(timer)
      }
    }
  }, [text, props.tab]);

  const fetchFindReport = async (numPage,controller) =>{
    let url = reportHelper.constructGetReportsUrl(props.tab, store.getState().fastInsertState.searchText, false, numPage, false);
    try {
        let reportsDatas = await fetch(url,{
          method:'GET',
          signal:controller.signal,
          headers: {
            "Authorization": `Token ${props.userKey}`,
            'Content-Type': 'application/json',
          }
        });
        switch (reportsDatas.status) {
          case 200:
            let data = await reportsDatas.json();
            props.loading(false)
            if (numPage !== 1) {
              setReportsData(reportsData.concat(data.results))
            }
            else {
              setReportsData(data.results)
            }
            if (data.meta.nextPageUri) {
              setPage({ currentPage: numPage + 1, canNext: true });
            }
            else {
              setPage({ currentPage: numPage, canNext: false });
            }
            //props.addFoundedReports(data.results);
          return;
          case 401:
            createPopupReportList(store,
              ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
              "O", {
              "O": () => {
                document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                document.location.reload(true);
              }
            });
          return;
          default:
            createPopupReportList(store,
              ["Не удалось получить список заключений!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте позднее."],
              "O", {});
        }
      } catch (error) {
        
      }
  };
  /* Scroll parameterts */
  const reportList = useRef(null);
  

  /* !!!! */
  /* useEffect(() => {
    if (props.addFoundedReports.length === 0) {
      let controller = new AbortController();
      startLoadNext(false)
      //if (!props.searchStatus) props.startLoad(true)
      fetchFindReport(1, controller)
      return () => {
        controller.abort()
      }
    }
  }, [props.reportsData, props.tabID, props.searchStatus === false, props.deleted]); */

  const scrollControll = () => {
    let scrolled = reportList.current.scrollHeight - reportList.current.scrollTop - reportList.current.offsetHeight;
    if (scrolled < 100 && scrolled !== 0) {
      let controller = new AbortController();
      reportList.current.removeEventListener("scroll", scrollControll);
      startLoadNext(true)
      fetchFindReport(page.currentPage, controller);
    }
  };

  useEffect(() => {
    if (props.addFoundedReports.length !== 0) {
      reportList.current.removeEventListener("scroll", scrollControll);
      props.setReportsData([]);
      setPage({ currentPage: 1, canNext: false });
    }
    else return;
  }, [props.tab]);

  useEffect(() => {
    if (page.canNext) {
      let obj = reportList.current;
      obj.addEventListener("scroll", scrollControll);
      return () => obj.removeEventListener("scroll", scrollControll);
    }
  }, [page]);
 /* !!!! */




  
  const mounthsArray = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
  const convertDate = (date) => {
    let tmpDate = new Date(date);
    return `${tmpDate.getDate()} ${mounthsArray[tmpDate.getMonth()]} ${tmpDate.getFullYear()}`;
  };

  const getReportText = (text) => {
    props.addFoundedText(text);
  };


  
  const generateReports = (bool) => {
    if(bool) return (<img className="FastInsertPreloader" src={loader} alt="preloader"/>)
    if (!reportsData) {
      return <div className="notFoundText">Введите текст для поиска</div>;
    }
    if (reportsData.length === 0) {
      return <div className="notFoundText">Заключения с указанным текстом не найдены</div>;
    }
    return reportsData.map((result, index) => {
      return (
        <div key={"fastReport_" + result.pk}>
          <div className={`newReportContainer ${selectedReport === result.pk ? "reportContainerSelect" : ""}`} onClick={() => { setSelectedReport(result.pk) }}>
            <div className="newTextContainer" onClick={() => { getReportText(result.text); }}>
              <div><span>{convertDate(result.date)}</span></div>
              <div><span>{result.patient_name}</span></div>
              <div>
                <img src={imageSwitcher(result.examination_area.icon)} alt={result.examination_area.area} />
                <span>{result.examination_area.area}</span>
              </div>
            </div>

          </div>
          <div className="experimental"></div>
        </div>
      );
  });

    
  };

  return (
    <>
    <div className="leftMenuSearchPanel">
      <div>
        <div>
          <input placeholder="Поиск по базе заключений..." onChange={(e) => { setText(e.target.value) }} value={text} />
        </div>
        <div>
          {searchIcon}
        </div>
      </div>
    </div>
    <div className="newreportsContainer" ref={reportList}>
      {generateReports(props.loadingBool)}
    </div>
    </>
  );
}