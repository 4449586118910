import React, { useState } from "react";
import "./auth.css";
import { connect } from "react-redux";
import { signInData } from "../../store/settings/actions";
import LoginView from "./components/loginView";
import RegistView from "./components/registView";
import RestoreView from "./components/restoreView";
import {isMobile} from 'react-device-detect';

function Auth(props) {
  const [currentViewAuth, setCurrentViewAuth] = useState("LOGIN");
  const currentProps = props;

  function switchAuthForm() {
    if (isMobile) {
      alert("Мобильные устройства временно не поддерживаются! Используйте десктопный браузер для работы.");
    }
    switch (currentViewAuth) {
    case "LOGIN":
      return <LoginView
        url={currentProps.url}
        email={currentProps.defaultTemp.emailHolder}
        changeView={setCurrentViewAuth}
        signInData={currentProps.signInData}
        passwordHolder={currentProps.defaultTemp.passwordHolder} />;
    case "REGISTRATION":
      return <RegistView
        url={currentProps.url}
        email={currentProps.defaultTemp.emailHolder}
        fio={currentProps.defaultTemp.fioHolder}
        signInData={currentProps.signInData}
        passwordHolder={currentProps.defaultTemp.passwordHolder}
        changeView={setCurrentViewAuth} />;
    case "RESTORE":
      return <RestoreView
        url={currentProps.url}
        changeView={setCurrentViewAuth} />;
    default:
      return <LoginView />;
    }
  }

  return switchAuthForm();


}

const mapStateToProps = state => {
  return {
    defaultTemp: state.defaultTemp,
    url: state.settings.url
  };
};
const mapDispatchToProps = {
  signInData
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);