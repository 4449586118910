import GlobalSettings from "../../settings/GlobalSettings";

const settings = {
  url: GlobalSettings.apiUrl,
  userMail: getCookie("userMail"),
  userFio: getCookie("userFio"),
  userKey: getCookie("userKey"),//Example: d7bc6a393015a175cd9080b48f38c27201b75434 
  currentPanel: "workPanel"
}

export const settingsReducers = (state = settings, action) => {
  switch (action.type) {
    case "SIGNIN_DATA":
      document.cookie = `userKey=${action.payloadKey}`;
      document.cookie = `userFio=${action.payloadFio}`;
      document.cookie = `userMail=${action.payloadEmail}`;
      return {
        ...state,
        userMail: action.payloadEmail,
        userFio: action.payloadFio,
        userKey: action.payloadKey,
        currentSpace: action.payloadKey ? "WORKSPACE" : "LOGIN"
      }
    case "CHANGE_PARAMETER_FROM_SETTINGS":
      document.cookie = `userFio=${action.payloadParameterData}`;
      return {
        ...state,
        userFio: action.payloadParameterData
      }
    case "CHANGE_CURRENTPANEL":
      return {
        ...state,
        currentPanel: state.currentPanel === "workPanel" ? "settingsPanel" : "workPanel"
      }
    case "SET_WORKPANEL":
      return {
        ...state,
        currentPanel: "workPanel"
      }
    case "SET_NOPAYMENTPANEL":
      return {
        ...state,
        currentPanel: "noPayments"
      }
    case "SET_SETTINGSPANEL":
      return {
        ...state,
        currentPanel: "settingsPanel"
      }
    default:
      return state;
  }
}

function getCookie(name) {
  var matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
