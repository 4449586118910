import $ from "jquery";
import React, { useState } from "react";
import StatusMessage from "../../../components/statusMessage/statusMessage";
import workWithPayments from "../../workSpace/workPanel/reportEditor/workWithPayments";

function LoginView(props) {
  const [inputEmail, setInputEmail] = useState({ error: false, text: "" });
  const [inputPassword, setInputPassword] = useState({ error: false, text: "" });
  const [unknownError, setUnknownError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const currentProps = props;

  function trySignIn() {
    setUnknownError(false);
    setErrorText("Укажите пароль");
    setInputEmail({ ...inputEmail, error: !inputEmail.text });
    setInputPassword({ ...inputPassword, error: !inputPassword.text });
    if (inputEmail.text && inputPassword.text) {
      var obj = this;
      $.ajax({
        type: "POST",
        url: `${currentProps.url}/auth/sign-in/`,
        contentType: "application/json",
        data: `{"email": "${inputEmail.text}", "password": "${inputPassword.text}"}`,
        statusCode: {
          401: () => { setErrorText("Почта или пароль введены неверно"); setInputPassword({ ...inputPassword, error: true }); },
          400: () => { setUnknownError(true); },
          404: () => { setUnknownError(true); }
        },
        success: (data) => {
          currentProps.signInData(data);
          let WWP = new workWithPayments();
          WWP.checkPayments(() => { });
        }
      });
    }
  }

  return (
    <div className="authBody">
      <div className="authLogo">
        <img alt="logo" src="/qr_logo_2_white.png" />
      </div>
      <div className="authContainer">
        <div className="inputGroup">
          <div className="dataInput">
            <input
              autoFocus={true}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  $(".customButton").click();
                }
              }}
              className="customInput"
              onChange={(e) => setInputEmail({ text: e.target.value, error: false })}
              placeholder={currentProps.email}
              style={{ "borderColor": inputEmail.error ? "#BD3131" : "#A6BDCA" }}
            />
            {inputEmail.error && <StatusMessage type="smallError smallErrorAuth" errorText="Укажите свою электронную почту" />}
          </div>
          <div className="dataInput">
            <input
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  $(".customButton").click();
                }
              }}
              className="customInput"
              type="password"
              onChange={(e) => setInputPassword({ text: e.target.value, error: false })}
              placeholder={currentProps.passwordHolder}
              style={{ "borderColor": inputPassword.error ? "#BD3131" : "#A6BDCA" }}
            />
            {inputPassword.error && <StatusMessage type="smallError smallErrorAuth" errorText={errorText || "Укажите пароль"} />}
          </div>
        </div>
        <div>
          <div>
            <button className="customButton" onClick={() => { trySignIn(); }}>Войти</button>
          </div>
        </div>
        <div className="bottomLink">
          <a href onClick={() => { currentProps.changeView("REGISTRATION"); }}>Регистрация</a>
          <a href onClick={() => { currentProps.changeView("RESTORE"); }}>Восстановление пароля</a>
        </div>
      </div>
      {unknownError && <StatusMessage type="unknownError" errorText="Что-то пошло не так... Пожалуйста, попробуйте еще раз позднее." />}
    </div>
  );
}

export default LoginView;