const leftMenu = {
   tabsData: null,
   currentTab: {
      tabID: 1,
      deleted: false,
   },
   search: {
      text: "",
      status: false
   },
   reportsData: [],
   selectedReport: null,
   selectFirstReport: false,
   rightPanel: 'default'
};

export const leftMenuReducer = (state = leftMenu, action) => {
   switch (action.type) {
      case "SET_TABSDATA":
         return {
            ...state,
            tabsData: action.payloadTabsData
         }
      case "SET_FIRST_REPORT":
         return {
            ...state,
            selectFirstReport: false
         }
      case "SET_REPORT_INDEX":
         return {
            ...state,
            selectedReport: action.payloadReportIndex
         }
      case "ON_LOAD_REPORT":
         return {
            ...state,
            rightPanel: 'ReportEditor',
            selectedReport: action.payloadReportIndex
         }
      case "SELECT_FIRST_REPORT":
         return {
            ...state,
            selectFirstReport: !state.selectFirstReport
         }
      case "ON_CHANGE_TAB":
         return {
            ...state,
            currentTab: {
               ...state.currentTab,
               tabID: action.payloadTabIndex,
            },
            rightPanel: "default",
            selectedReport: null,
            search: {
               text: "",
               status: false
            }
         };
      case "ONCHANE_SEARCH_TEXT":
         return {
            ...state,
            search: {
               text: action.payloadSearchText,
               status: action.payloadSearchText.length > 2
            }
         }
      case "ON_CREATE_NEW_REPORT":
         return {
            ...state,
            rightPanel: state.rightPanel !== "CategorySelector" ? "CategorySelector" : "default",
            selectedReport: null
         }
      case "CHANGE_CURRENTPANEL":
         return {
            ...state,
            rightPanel: "default"
         }
      case "ON_SELECT_END_NODE":
         return {
            ...state,
            rightPanel: "ReportEditor",
         }
      case "SET_REPORTSDATA":
         if (action.payloadFirstSelect) {
            state.selectFirstReport = true;
         }
         return {
            ...state,
            reportsData: action.payloadReportsData
         }
      case "SET_DEFAULT_RIGHTPANEL":
         return {
            ...state,
            rightPanel: "default"
         }
      case "DESELECT_REPORT":
         return {
            ...state,
            selectedReport: null
         }
      case "ON_SELECT_REPORT":
         return {
            ...state,
            rightPanel: "ReportEditor",
            reportPK: action.payloadReportPK
         }
      case "DOR_REPORT":
         state.reportsData.splice(action.payloadReportIndex, 1);
         if (action.payloadCloseEditor) {
            state.rightPanel = "default";
         }
         return {
            ...state,
            reportsData: [...state.reportsData],
         }
      case "SET_NEW_DEL_BTN":
         return {
            ...state,
            currentTab: {
               ...state.currentTab,
               deleted: action.payloadDeleteStatus
            }
         }
      default:
         return state;
   }
}