import React from "react";
import "./leftMenu.css";
import { setToCurrentMD5 } from "../../../../store/editorState/actions";
import { onChangeTab } from "../../../../store/leftMenu/actions";
import { connect } from "react-redux";
import tmpObj from "../reportEditor/checkModReport";

function TabsPanel(props) {
  const currentProps = props;

    function computeTabStyleClass(selectedTabId, tabId, tabIndex) {
        if (selectedTabId === tabId) return "tabsSelected";
        return "";
    }

  function generateTabs() {
    return currentProps.tabsData.map((data, index) => {
      return (
        <div key={"tab_" + index}
          className={computeTabStyleClass(currentProps.currentTab, data.pk, 0)}
          onClick={() => {
            tmpObj.check(() => {
              currentProps.onChangeTab(data.pk);
            });
          }}>
          <div></div>
          <span>
            {data.title}
          </span>
        </div>
      );
    });
  }
  return (
    <div className="leftMenuTabs">
      {currentProps.tabsData && generateTabs()}
    </div>
  );
}

const mapDispatchToProps = {
  onChangeTab,
  setToCurrentMD5
};
const mapStateToProps = store => {
  return {
    tabsData: store.leftMenu.tabsData,
    currentTab: store.leftMenu.currentTab.tabID
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsPanel);