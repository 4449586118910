import store from '../../index';
import $ from 'jquery';
import md5 from "react-native-md5";

export const onChangeTab = (index) => {
  return {
    type: "ON_CHANGE_TAB",
    payloadTabIndex: index
  }
}
export const setTabsData = (data) => {
  return {
    type: "SET_TABSDATA",
    payloadTabsData: data
  }
}
export const setReportsData = (data) => {
  return {
    type: "SET_REPORTSDATA",
    payloadReportsData: data
  }
}
export const onCreateNewReport = () => {
  /*   let data = store.getState().editorState;
    let tmpArray = [];
    data.categoryArray.forEach(function (item, index) {
      if (item['necessarily'] === true) {
        tmpArray.push(item);
      }
    }); */
  return {
    type: "ON_CREATE_NEW_REPORT"
  }
}
export const onSelectEndNode = (nodePK, nodeType) => {
  return {
    type: "ON_SELECT_END_NODE",
    payloadNodePK: nodePK,
    payloadNodeType: nodeType
  }
}
export const onSelectReport = (index) => {
  return {
    type: "SET_REPORT_INDEX",
    payloadReportIndex: index
  }
}

export const onChangeSearchTextRep = text => {
  return {
    type: "ONCHANE_SEARCH_TEXT",
    payloadSearchText: text
  }
}
export const DoRreport = (index, close) => {
  return {
    type: "DOR_REPORT",
    payloadReportIndex: index,
    payloadCloseEditor: close
  }
}
export const setFirstReport = () => {
  return {
    type: "SET_FIRST_REPORT"
  }
}
export const changeBtnNewDel = bool => {
  return {
    type: "SET_NEW_DEL_BTN",
    payloadDeleteStatus: bool
  }
}

export const selectCurrentReport = (pk, reportIndex) => {
  //debugger;
  //Вторая загрузка
  //console.log(store.getState().editorState.reportPK);
  if (store.getState().editorState.reportPK === null) {
    store.dispatch({
      type: 'SELECT_FIRST_REPORT'
    });
    store.dispatch({
      type: 'SET_REPORTSDATA',
      payloadReportsData: []
    });
    //let intV = setInterval(function () {
    //  if (store.getState().leftMenu.reportsData.length > 0) {
    //    clearInterval(intV);
    //    selectCurrentReport(store.getState().leftMenu.reportsData[0].pk, store.getState().leftMenu.reportsData[0].pk);
    //  }
    //}, 100);
  }
  else {
    $.ajax({
      type: "GET",
      url: `${store.getState().settings.url}/examinations/reports/${pk}/`,
      contentType: 'application/json',
      crossDomain: true,
      headers: {
        'Authorization': `Token ${store.getState().settings.userKey}`
      },
      success: function (data) {
        store.dispatch({
          type: 'SET_REPORTSDATA',
          payloadReportsData: []
        });
        store.dispatch({
          type: 'SET_MD5_ADDITIONAL',
          md5: md5.hex_md5(data.date + data.patient_name + data.patient_date_of_birth)
        });
        store.dispatch({
          type: 'SET_MD5',
          md5: data.text ? md5.hex_md5(data.text) : ""
        });
        store.dispatch({
          type: 'ON_LOAD_REPORT',
          payloadReportData: data,
          payloadReportIndex: reportIndex
        });
        let fieldOfStudy = '';
        store.getState().leftMenu.reportsData.forEach(function (d, i) {
          if (d.pk === pk) {
            fieldOfStudy = d.node_title;
          }
        });
        store.dispatch({
          type: 'SET_FIELD_OF_STUDY',
          fieldOfStudy: fieldOfStudy
        });
        if (store.getState().fastInsertState.searchText !== "") {
          store.dispatch({
            type: 'ADD_FOUND_TEXT',
            payloadReportsText: data.text
          });
        }
      },
      error: function (data) {
        //createPopupReportList(store,
        //  ["Не удалось выполнить операцию!", <br />, "Пожалуйста, попробуйте позднее."],
        //  "O", {});
      }
    });
  }
}