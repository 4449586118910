const fastInsert = {
    show: false,
    tmpCurrentTab: null,
    searchText: "",
    foundReports: null,
    foundText: ""
}
export const fastInsertReducer = (state = fastInsert, action) => {
    switch (action.type) {
        case "TOOGLE_FAST_INSERT":
            return {
                ...state,
                searchText: "",
                foundReports:null,
                tmpCurrentTab: null,
                show: !state.show
            }
        case "CHANGE_SELECTED_TAB":
            return {
                ...state,
                foundText: "",
                tmpCurrentTab: action.payloadTabIndex
            }
        case "CHANGE_SEARCH_TEXT_FI":
            return {
                ...state,
                foundText: "",
                searchText: action.payloadText
            }
        case "ADD_FOUND_REPORTS":
            return {
                ...state,
                foundReports: action.payloadReportsArray
            }
        case "ADD_FAST_TEXT":
            if (state.searchText !== "") {
                let str = state.searchText.trim();
                let reg = new RegExp(`${str}`, "g");
                return {
                    ...state,
                    foundText: action.payloadReportsText.replace(reg, `<span class='highlightText'>${str}</span>`)
                }
            }
            else {
                return {
                    ...state,
                    foundText: action.payloadReportsText
                }
            }
        default:
            return state
    }
}