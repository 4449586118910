export const changeSelectedTab = (index)=>{
   return{
       type:"CHANGE_SELECTED_TAB",
       payloadTabIndex: index,
   }
}
export const createReports = (name)=>{
   return{
       type:"CREATE_REPORT",
       payloadRightPanel: name,
   }
}
export const addCategoryArray = (array,currentLevel,currentSelected)=>{
    return{
        type:"ADD_ARRAY_CATEGORY",
        payloadArray: array,
        payloadLevel: currentLevel,
        payloadSelected:currentSelected
    }
}