import React, { Component } from "react";
import { createPopupReportList } from "../../../store/editorState/actions";
import { connect } from "react-redux";
import $ from "jquery";
import StatusMessage from "../../../components/statusMessage/statusMessage";
import store from "../../../index";

class registView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ErrorText: "",
      unknownError: false
    };
  }

  checkInputData() {
    this.setState(
      {
        unknownError: false,
        inputFioError: this.state.inputFio ? false : true,
        inputEmailError: this.state.inputEmail ? false : true,
        inputPasswordError: this.state.inputPassword ? false : true
      },
      () => {
        if (!this.state.inputFioError && !this.state.inputEmailError && !this.state.inputPasswordError) {
          this.tryCreateAccount();
        }
      });
  }
  tryCreateAccount() {
    let tmp = this;
    $.ajax({
      type: "POST",
      url: `${this["props"].url}/auth/sign-up/`,
      contentType: "application/json",
      data: "{\"first_name\": \"" + this.state.inputFio + "\", \"email\": \"" + this.state.inputEmail + "\", \"password\": \"" + this.state.inputPassword + "\"}",
      statusCode:{
        500:  ()=>{tmp.emailErrorHandler("Error email")}
      },
      success: function (data) {
        createPopupReportList(store,
          ["Поздравляем, вы успешно зарегистрированы! Письмо с важной информацией о системе отправлено вам на почту."],
          "O", {}, "default","simple");
        tmp.props.signInData(data);
      },
      error: function (data) {
        if(data.responseText && data.status !== 404){
          tmp.emailErrorHandler(data.responseText)
        }
        else {
          tmp.setState({ unknownError: true });
        }
      }
    });
  }
  emailErrorHandler(code) {
    switch (code) {
      case "{'email': ['User with this email already exists']}":
        this.setState({
          ErrorText: "Адрес уже занят, используйте другой",
          inputEmailError: true
        });
        break;
      case "Error email":
        this.setState({
          ErrorText: "Введите корректный адрес электронной почты",
          inputEmailError: true
        });
        break;
      default:
    }
  }
  onChangeInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: false
    });
  }
  render() {
    return (
      <div className="authBody">
        <div className="authLogo">
          <img alt="logo" src="/qr_logo_2_white.png" />
        </div>
        <div className="authContainer">
          <div className="inputGroup">
            <div className="dataInput">
              <input autoFocus={true} className="customInput"
                name="inputFio"
                onChange={(e) => this.onChangeInput(e)}
                placeholder={this["props"].fio}
                style={{ "borderColor": this.state.inputFioError ? "#BD3131" : "#A6BDCA" }}
              />
              {this.state.inputFioError && <StatusMessage type="smallError smallErrorAuth" errorText="Укажите свое имя" />}
            </div>
            <div className="dataInput">
              <input className="customInput"
                name="inputEmail"
                onChange={(e) => this.onChangeInput(e)}
                placeholder={this["props"].email}
                style={{ "borderColor": this.state.inputEmailError ? "#BD3131" : "#A6BDCA" }}
              />
              {this.state.inputEmailError && <StatusMessage type="smallError smallErrorAuth" errorText={this.state.inputEmail ? this.state.ErrorText : "Укажите свою электронную почту"} />}
            </div>
            <div className="dataInput">
              <input className="customInput" type="password"
                name="inputPassword"
                onChange={(e) => this.onChangeInput(e)}
                placeholder={this["props"].passwordHolder}
                style={{ "borderColor": this.state.inputPasswordError ? "#BD3131" : "#A6BDCA" }} />
              {this.state.inputPasswordError && <StatusMessage type="smallError smallErrorAuth" errorText="Укажите пароль" />}
            </div>
          </div>
          <div>
            <div><button className="customButton" onClick={() => { this.checkInputData(); }}>Создать аккаунт</button></div>
          </div>
          <div className="bottomLink">
            <a href onClick={() => { this["props"].changeView("LOGIN"); }}>Авторизация</a>
          </div>
        </div>
        {this.state.unknownError && <StatusMessage type="unknownError" errorText="Что-то пошло не так... Пожалуйста, попробуйте еще раз позднее." />}
      </div>
    );
  }
}

export default connect()(registView);