import React, { useEffect } from "react";
import { connect } from "react-redux";
import { addCategoryArray } from "../../../../store/workState/actions";
import { onSelectEndNode } from "../../../../store/leftMenu/actions";
import $ from "jquery";
import NodeColumn from "./nodeColumn";
import "./categorySelector.css";
import store from "../../../../index";
import { createPopupReportList } from "../../../../store/editorState/actions";

function CategorySelector(props) {
  useEffect(() => {
    tryGetCategory(props.currentTabIndex.tabID, 0, null);
  }, []);

  const tryGetCategory = (pk, selectedColumn, selectedRow) => {
    $.ajax({
      async: false,
      type: "GET",
      url: `${props.url}/examinations/navigation/${pk}/`,
      contentType: "application/json",
      crossDomain: true,
      headers: {
        "Authorization": `Token ${store.getState().settings.userKey}`
      },
      success: function (data) {
        //debugger
        let nodesArray = data.children;

        if(!nodesArray.some((node) => node.type === 0)) {
          store.dispatch({
            type: 'SET_NODE_ID',
            payload: {
              nodeId: pk
            }
          });
        }

        if(!nodesArray.some((node) => node.type === 3 /* found shared report */ || node.type === 0 /* regular node */)) {
          nodesArray.push({ pk: pk, title: "Текстовое заключение", type: 2 })
        }

        props.addCategoryArray(nodesArray, selectedColumn, selectedRow);
      },
      error: function (data) {
        if (data.status === 401) {
          createPopupReportList(store,
            ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
            "O", {
            "O": () => {
              document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.location.reload(true);
            }
          });
        }
        else {
          createPopupReportList(store,
            ["Не удалось выполнить операцию!", <br key={"createPopupReportList_" + pk} />, "Пожалуйста, попробуйте позднее."],
            "O", {});
        }
      }
    });
  };

  const loadStartTextForTextReport = (nodeOrReportPk, isReportPk) => {
    var queryParam = "?" + (isReportPk ? "report_pk=" : "node_pk=") + nodeOrReportPk;
    $.ajax({
      type: "GET",
      url: `${store.getState().settings.url}/examinations/reports/report_start_text${queryParam}`,
      contentType: 'application/json',
      crossDomain: true,
      headers: {
        'Authorization': `Token ${store.getState().settings.userKey}`
      },
      success: function (data) {
        store.dispatch({
          type: 'SET_START_TEXT',
          payloadStartText: data.data
        });
      }
    });
  }

  const generateColumns = () => {
    return props.arrays.map((columns, index) => {
      return (
        <NodeColumn
          key={"NodeColumn_" + index}
          main={index === 0 ? true : false}
          data={columns}
          //rowSelected={props.rowSelected[index]}
          getData={tryGetCategory}
          nodeIndex={index}
          startText={loadStartTextForTextReport}
          endNode={props.onSelectEndNode}
        />
      );
    });
  };

  return (
    <div className="categorySelectorContainer">
      <div className="topHeader">
        <span>Новое заключение</span>
      </div>
      <div className="categoryList">
        <div>
          {generateColumns()}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currentTabIndex: state.leftMenu.currentTab,
    arrays: state.arrays.categoryArray,
    rowSelected: state.arrays.currentRowsArray,
    url: state.settings.url
  };
};

const mapDispatchToProps = {
  addCategoryArray,
  onSelectEndNode
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelector);