const paymentData = {
    icon: "",
    status: "",
    description: "",
    linkText: "",
    valid: "",
    price: ""
}

export const paymentsReducer = (state = paymentData, action) => {
    switch (action.type) {
        case "SET_PAYMENT_DATA":
            return {
                ...state,
                icon: action.payloadIcon,
                status: action.payloadStatus,
                description: action.payloadDescription,
                linkText: action.payloadLinkText,
                valid: action.payloadValid,
                price: action.payloadPrice
            }
        case "SET_PAYMENT_DESCRIPTION":
            //debugger;
            return {
                ...state,
                description: action.payloadDescription
            }
        default:
            return state;
    }
}
