import React, { useState } from "react";

export default function Tabs(props) {
  const [currentTab, setCurrentTab] = useState(props.startTab);

  function computeTabStyleClass(selectedTabId, tabId, tabIndex) {
    if (selectedTabId === tabId) return "tabsSelected";
    return "";
  }

  return (
    <div className="leftMenuTabs">
      {props.tabsData.map((data, index) => {
        return (
          <div key={"tab_" + index}
            className={computeTabStyleClass(currentTab,data.pk, 0)}
            onClick={() => {
              props.changeTab(data.pk); setCurrentTab(data.pk);
            }}>
            <div></div>
            <span>
              {data.title}
            </span>
          </div>
        );
      })
      }
    </div>
  );
}