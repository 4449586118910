import React from "react";
import "./css/popupContainer.css";
import "./css/loader.css";
import SubscriptionFrom from "./subscriptionFrom";
import ChangePassword from "./changePassword";
import SavaAndSend from "./saveAndSend";
import Simple from "./simple";
import { connect } from "react-redux";
import $ from "jquery";
import store from "../../index";

class PopupContainer extends React.Component {

  componentDidMount() {
    return window.addEventListener("keydown", this.closePopups, false);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.closePopups);
  }

  closePopups (e) {
    if(e.keyCode === 27) {
      store.dispatch({
        type: "CHANGE_POPUP_DATA",
        popupData: []
      });
    }
  }

  status = {
    warning: "#B34242",
    sucsess: "#89D2A8",
    default: "#466289"
  }

  switchBodyData(data) {
    switch (data.popupType) {
      case "subscriptionFrom":
        {
          return <SubscriptionFrom />;
        }
      case "saveAndSend":
        {
          return <SavaAndSend data={data} />;
        }
      case "simple":
        {
          return <Simple data={data} buttonType={data.buttonType} text={data.text} />;
        }
      case "changePassword":
        {
          return <ChangePassword data={data} />;
        }
      default: return "";
    }
  }

  randomizator() {
    let maxLeft = window.innerWidth / 2;
    let maxTop = window.innerHeight / 2;
    return [Math.random() * (maxLeft - 100) + 100, Math.random() * (maxTop - 100) + 100];
  }

  render() {
    let tmp = this["props"].popupData.popupData;
    if (Object.keys(tmp).length > 0) {
      $(".containerForPopup").width("100%");
      $(".containerForPopup").height("100%");
      $("body").css("position", "fixed");
      $("body").css("overflow", "hidden");

      return tmp.map((data, index) => {
        if (data.text[0].indexOf("preloader_") !== -1) {
          const tmpText = data.text[0].substr(10, data.text[0].length);
          return <div class="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div className="preloaderText">{tmpText}</div>
          </div>
        }
        else {
          return (
            <div key={"popupContainer_" + index} className="popupContainer" style={{ marginLeft: index * 20, marginTop: index * 20 }}
                 onClick={(e) => e.stopPropagation()}>
              <div style={{ backgroundColor: this.status[data.headColor] }} className="head"></div>
              <div className="body">
                {this.switchBodyData(data)}
              </div>
              <div className="bottom"></div>
            </div>
          );
        }
      });
    }
    else {
      $(".containerForPopup").width(1);
      $(".containerForPopup").height(1);
      $("body").css("position", "");
      $("body").css("overflow", "");
      return <div></div>;
    }
  }
}

const mapStateToProps = state => {
  return {
    popupData: state.workState
  };
};
export default connect(mapStateToProps)(PopupContainer);


/* import React,{useEffect,useState} from "react";
import "./css/popupContainer.css";
import "./css/loader.css";
import SubscriptionFrom from "./subscriptionFrom";
import ChangePassword from "./changePassword";
import SavaAndSend from "./saveAndSend";
import Simple from "./simple";
import { connect } from "react-redux";
import $ from "jquery";
import store from "../../index";

const PopupContainer = (props) => {
  const [popupData,setPopupData] = useState(props.popupData)
  
  useEffect(() => {
    setPopupData(props.popupData)
    console.log(props.popupData,popupData)
  }, [props.popupData])

  useEffect(() => {
    window.addEventListener("keydown", closePopups);
    return ()=>{
      window.removeEventListener("keydown", closePopups);
    }
  }, [])

  const closePopups = (e) =>{
    if(e.keyCode === 27) defaultButtonEvent()
  }
  
  const defaultButtonEvent = () => {
    store.dispatch({
      type: "CHANGE_POPUP_DATA",
      popupData: []
    });
  }

  let status = {
    warning: "#B34242",
    sucsess: "#89D2A8",
    default: "#466289"
  }

  const switchBodyData = (data) => {
    switch (data.popupType) {
      case "subscriptionFrom":
        {
          return <SubscriptionFrom />;
        }
      case "saveAndSend":
        {
          return <SavaAndSend data={data} />;
        }
      case "simple":
        {
          return <Simple data={data} buttonType={data.buttonType} text={data.text} />;
        }
      case "changePassword":
        {
          return <ChangePassword data={data} />;
        }
      default: return "";
    }
  }

  const randomizator = () => {
    let maxLeft = window.innerWidth / 2;
    let maxTop = window.innerHeight / 2;
    return [Math.random() * (maxLeft - 100) + 100, Math.random() * (maxTop - 100) + 100];
  }
  console.log(popupData)
  if (Object.keys(popupData).length > 0) {
    console.log(2)
    $(".containerForPopup").width("100%");
    $(".containerForPopup").height("100%");
    $("body").css("position", "fixed");
    $("body").css("overflow", "hidden");
    return popupData.map((data, index) => {
      if (data.text[0].indexOf("preloader_") != -1) {
        const tmpText = data.text[0].substr(10, data.text[0].length);
        return <div class="loader">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div className="preloaderText">{tmpText}</div>
        </div>
      }
      else {
        return (
          <div key={"popupContainer_" + index} className="popupContainer" style={{ marginLeft: index * 20, marginTop: index * 20 }}>
            <div style={{ backgroundColor: status[data.headColor] }} className="head"></div>
            <div className="body">
              {switchBodyData(data)}
            </div>
            <div className="bottom"></div>
          </div>
        );
      }
    });
  }
  else {
    $(".containerForPopup").width(1);
    $(".containerForPopup").height(1);
    $("body").css("position", "");
    $("body").css("overflow", "");
    return <div></div>;
  }
}

const mapStateToProps = state => {
  return {
    popupData: state.workState.popupData
  };
};
export default connect(mapStateToProps)(PopupContainer); */