import React from "react";
import "./style.css"

export default function StatusMessage (props)
{
    return(
        <span className={props.type? props.type : "smallError"}>
            {props.errorText}
        </span>
    )
}