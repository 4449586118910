import React, { useState, useEffect } from "react";
import $ from "jquery";
import "./settingsPanel.css";
import { connect } from "react-redux";
import { onChangeSettings } from "../../../store/settings/actions";
import { createPopupReportList } from "../../../store/editorState/actions";
import store from "../../../index";
import workWithPayments from "../workPanel/reportEditor/workWithPayments";
import { imageSwitcher } from "./images/images";

function SettingsPanel(props) {

  const [fullSettingsData, setSettingsData] = useState({}); //State: - store settings after success request or {}
  const [tmpHeader, setTmpHeader] = useState(""); //State: - tmp data for editing tab header. If success rewrite settings state
  const [tmpName, setTmpName] = useState(""); //State: - tmp data for editing name. If success rewrite settings state
  const [currentTab, setCurrentTab] = useState(props.currentTab); //State: - store index for tabs control

  //Effect: -try load settings parameters
  useEffect(() => {
    paymentScript();
    getSettingsParameters();
  }, []);

  var WWP = new workWithPayments();

  //Func: -request to get settings parameters
  const getSettingsParameters = () => {
    $.ajax({
      type: "GET",
      url: `${props.settings.url}/user`,
      contentType: "application/json",
      crossDomain: true,
      headers: {
        "Authorization": `Token ${store.getState().settings.userKey}`
      },
      success: function (data) {
        setSettingsData(data);
        setTmpName(data.first_name)
        setTmpHeader(data[`${getTabText(props.tabsData[currentTab - 1].title)}`])
      },
      error: function () {

      }
    });
  }

  //Func: -Convert tabs title to settings header
  const getTabText = (tabName) => {
    switch (tabName) {
      case "МРТ":
        return "mri_header";
      case "КТ":
        return "kt_header";
      case "Рентген":
        return "xray_header";
      case "УЗИ":
        return "ultrasound_header";
      default:
        return "NewTabError";
    }
  }

  //Func: -Make request and try to save new settings parameter
  const makeSettingsSaveRequest = (parameterName, parameterData) => {
    $.ajax({
      type: "PUT",
      url: `${props.settings.url}/user`,
      contentType: "application/json",
      crossDomain: true,
      data: `{"${parameterName}":"${shieldingSymbols(parameterData)}"}`,
      headers: {
        "Authorization": `Token ${store.getState().settings.userKey}`
      },
      success: function () {
        if (parameterName === "first_name") {
          props.onChangeSettings("userFio", parameterData)
        } else {
          setSettingsData({ ...fullSettingsData, [parameterName]: parameterData });
        }
        if (parameterName === "password") {
          createPopupReportList(store,
            ["Пароль успешно изменен. Обновите страницу, чтобы заново зайти в учетную запись"],
            "O", { "O": () => { clearUserCookies(); } }, "sucsess");
        } else {
          createPopupReportList(store,
            ["Настройки успешно изменены"],
            "O", {}, "sucsess");
        }
      },
      error: function (data) {
        if (data.status === 401) {
          createPopupReportList(store,
            ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
            "O", {
            "O": () => {
              document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.location.reload(true);
            }
          });
        }
        else {
          if (parameterName === "first_name") {
            setTmpName(fullSettingsData.first_name)
          }
          createPopupReportList(store,
            ["Не удалось изменить настройки. Попробуйте позднее"],
            "O", {});
        }
      }
    });
  }

  //Func: -shielding symbols
  function shieldingSymbols(text) {
    return text.replace(/\"/g, '\\"').replace(/\n/g, "\\n")
  }

  function computeTabStyleClass(selectedTabId, tabId, tabIndex) {
    if (selectedTabId === tabId) return "tabsSelected";
    return "";
  }

  //Func: -generate tabs from leftMenu component's data
  function generateTabs() {

    return props.tabsData.map((data, index) => {
      return (
        <div key={"tab_" + index}
          className={computeTabStyleClass(currentTab, data.pk, 0)}
          onClick={() => {
            setCurrentTab(data.pk);
            let parameter = fullSettingsData[`${getTabText(props.tabsData[index].title)}`]
            setTmpHeader(typeof parameter == "undefined" ? "" : parameter);
          }}>
          <div></div>
          <span>
            {data.title}
          </span>
        </div>
      );
    });
  }

  //Method: - clear user data from browser's cookies and reload page
  const clearUserCookies = () => {
    document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.location.reload(true);
  }

  const paymentScript = () => {
    if (document.getElementById("paymentScript") === null) {
      let newScript = document.createElement("script");
      newScript.src = "https://widget.cloudpayments.ru/bundles/cloudpayments";
      newScript.id = "paymentScript";
      document.head.append(newScript);
    }
    WWP.checkPayments();
  }

  //Render: - render component
  return (
    <div className="settingsPanel">
      <div className="topHeader">
        <span style={{ "marginLeft": "auto" }}>Личный кабинет</span>
        <div style={{ "marginLeft": "auto", "marginRight": "10px", "height": "19px", "cursor": "pointer" }} onClick={() => {
          WWP.checkPayments(() => {
            store.dispatch({
              type: "CHANGE_CURRENTPANEL"
            });
          });
        }}>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.451 3.38849C16.6829 3.15664 16.6829 2.78074 16.451 2.54889C16.2192 2.31704 15.8433 2.31704 15.6114 2.54889L2.54889 15.6114C2.31704 15.8432 2.31704 16.2191 2.54889 16.451C2.78074 16.6828 3.15664 16.6828 3.38849 16.451L16.451 3.38849Z" fill="#757575" />
            <path d="M3.38849 2.54889C3.15664 2.31704 2.78074 2.31704 2.54889 2.54889C2.31704 2.78074 2.31704 3.15664 2.54889 3.38849L15.6114 16.451C15.8433 16.6828 16.2192 16.6828 16.451 16.451C16.6829 16.2191 16.6829 15.8432 16.451 15.6114L3.38849 2.54889Z" fill="#757575" />
          </svg>
        </div>
      </div>
      <div>
        <div className="infoHeader">
          <div className="textInput">
            <span>ФИО</span>
            <input type="text" value={tmpName}
              onChange={(e) => { setTmpName(e.target.value) }}
              onBlur={(e) => {
                if (e.target.value) {
                  makeSettingsSaveRequest("first_name", e.target.value)
                } else {
                  setTmpName(fullSettingsData.first_name)
                }
              }} />
          </div>
          <div className="textInput ti-disable">
            <span>Почта</span>
            <input type="text" value={props.settings.userMail} disabled={true} />
          </div>
        </div>
        <div className="settingsControlContainer">
          <div>
            <span className="categoryHeader">Шапка заключения</span>
            <div className="settingsTabsPanel">
              <div className="leftMenuTabs">
                {props.tabsData && generateTabs()}
              </div>
              <div>
                <textarea value={tmpHeader}
                  onChange={(e) => { setTmpHeader(e.target.value) }}
                  onBlur={(e) => {
                    let property = `${getTabText(props.tabsData[currentTab - 1].title)}`;
                    if (e.target.value !== fullSettingsData[property]) {
                      makeSettingsSaveRequest(`${getTabText(props.tabsData[currentTab - 1].title)}`, e.target.value)
                    }
                  }
                  } />
              </div>
            </div>
          </div>
          <div className="paymentPanel" hidden={true}>
            <span className="categoryHeader">Подписка</span>
            <div className="paymentHead">
              <div>{imageSwitcher(store.getState().payments.icon)}</div>
              <div className="paymentStatus">{store.getState().payments.status}</div>
            </div>
            <div className="paymentDescription">{store.getState().payments.description}</div>
            <div className={store.getState().payments.linkText ? "paymentLinkText" : ""} onClick={() => {
              //debugger;
              if (store.getState().payments.linkText != "Отписаться") {
                WWP.pay();
              }
              else {
                createPopupReportList(store,
                  ["Вы уверены, что хотите отменить подписку?"],
                  "C_Unsub", {
                  "C": () => { },
                  "Unsub": () => { WWP.cancelPayment() }
                },
                  "warning");
              }
            }}>{store.getState().payments.linkText}</div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <span onClick={() => {
            createPopupReportList(store, [""], "C_SaP", { "sAs": function n(password) { makeSettingsSaveRequest("password", password) } }, "default", "changePassword");
            //Для имитации блокировки оплаты
            /*$.ajax({
              type: "POST",
              url: `${store.getState().settings.url}/payments/valid`,
              contentType: "application/json",
              body: false,
              headers: {
                "Authorization": `Token ${store.getState().settings.userKey}`
              },
              success: (data) => {
                //debugger;
                if (data.success === true) {
                  WWP.checkPayments();
                }
                else {
                  //store.dispatch({
                  //  type: "SET_NOPAYMENTPANEL"
                  //});
                }
              }
            });*/
          }}>Изменить пароль</span>
          <span onClick={() => {
            createPopupReportList(store,
              ["Выйти из аккаунта?"],
              "C_E", {
              "C": () => { },
              "E": () => { clearUserCookies() }
            },
              "default");
          }}>Выйти из аккаунта</span>
        </div>
      </div>
    </div >
  );
}

const mapDispatchToProps = {
  createPopupReportList,
  onChangeSettings
};

const mapStateToProps = state => {
  return {
    settings: state.settings,
    tabsData: state.leftMenu.tabsData,
    currentTab: state.leftMenu.currentTab.tabID,
    paymentStatus: store.getState().payments.status,
    paymentDescription: store.getState().payments.description
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel);