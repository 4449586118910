import React, { useEffect, useState } from "react";
import "./leftMenu.css";
import { onChangeSearchTextRep } from "../../../../store/leftMenu/actions";
import { searchIcon } from "../images/images";
import { connect } from "react-redux";
import $$ from "jquery.animate";
import {getCookie} from "../../../../helpers/CookieHelper";

function SearchComponent(props) {
  const [text, setText] = useState("")

  useEffect(() => {
      blinkSearch(4);
      if (text.trim().length > 2) {
          const timer = setTimeout(() => {
              props.startLoad(true)
              props.onChangeSearchTextRep(encodeURIComponent(text));
          }, 600);
      return () => {clearTimeout(timer);}
    } else {
      props.onChangeSearchTextRep("");
    }
  }, [text,props.currentTab]);

  return (
    <div className="leftMenuSearchPanel">
      <div>
        <div>
          <input id="searchInput" placeholder="Поиск по базе заключений..."
                 onChange={(e) => { setText(e.target.value) }} value={text}/>
        </div>
        <div>
          {searchIcon}
        </div>
      </div>
    </div>
  );
}

function blinkSearch(times) {
    if(getCookie("searchBlinked") === "true") return;

    var duration = 700;
    for(var i=0; i<times; i++) {
        $$('.leftMenuSearchPanel div:first-child').fadeOut(duration);
        $$('.leftMenuSearchPanel div:first-child').fadeIn(duration);
    }
    document.cookie = `searchBlinked=true`;
}

const mapDispatchToProps = {
  onChangeSearchTextRep
};

const mapStateToProps = store => {
  return {
    currentTab: store.leftMenu.currentTab,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);