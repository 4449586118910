export const signInData = (data) => {
    return {
        type: "SIGNIN_DATA",
        payloadEmail: data.email,
        payloadFio: data.first_name,
        payloadKey: data.key
    }
}
export const onChangeCurrentPanel = () => {
    return {
        type: "CHANGE_CURRENTPANEL"
    }
}
export const onChangeSettings = (parameterName,parameterData) => {
    return {
        type: "CHANGE_PARAMETER_FROM_SETTINGS",
        payloadParameterName:parameterName,
        payloadParameterData:parameterData
    }
}
