import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "./reportsList.css";
import { setFirstReport, onSelectReport, DoRreport, setReportsData } from "../../../../store/leftMenu/actions";
import { parseData, onLoadReport, changeFieldOfStudy, saveMD5, saveMD5controls, saveAdditionalMD5, setDefaultWorkstate, createPopupReportList } from "../../../../store/editorState/actions";
import { addFoundedText } from "../../../../store/fastInsert/actions";
import { imageSwitcher, deleteIcon, returnIcon } from "../images/images";
import preloader from "../images/spinner.svg";
import { connect } from "react-redux";
import store from "../../../../index";
import tmpObj from "../reportEditor/checkModReport";
import LRD from "./loadReportsData";
import _ from 'lodash';
import * as reportHelper from "../../../../helpers/reportHelper";

function ReportsList(props) {
  const [page, setPage] = useState({ currentPage: 1, canNext: false });
  const [isLoadNext, startLoadNext] = useState(false);
  const reportList = useRef(null);

  const mounthsArray = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
  const convertDate = (date) => {
    let tmpDate = new Date(date);
    return `${tmpDate.getDate()} ${mounthsArray[tmpDate.getMonth()]} ${tmpDate.getFullYear()}`;
  };

  useEffect(() => {
    if (props.reportsData.length === 0) {
      let controller = new AbortController();
      startLoadNext(false)
      if (!props.searchStatus) props.startLoad(true)
      fetchReportsData(1, controller)
      return () => {
        controller.abort()
      }
    }
  }, [props.reportsData, props.tabID, props.searchStatus, props.searchText, props.deleted]);

  const scrollControll = () => {
    let scrolled = reportList.current.scrollHeight - reportList.current.scrollTop - reportList.current.offsetHeight;
    if (scrolled < 100 && scrolled !== 0) {
      let controller = new AbortController();
      reportList.current.removeEventListener("scroll", scrollControll);
      startLoadNext(true)
      fetchReportsData(page.currentPage, controller);
    }
  };

  useEffect(() => {
    if (props.reportsData.length !== 0) {
      reportList.current.removeEventListener("scroll", scrollControll);
      props.setReportsData([]);
      setPage({ currentPage: 1, canNext: false });
    }
    else return;
  }, [props.tabID, props.searchStatus, props.searchText, props.deleted]);

  useEffect(() => {
    if (page.canNext) {
      let obj = reportList.current;
      obj.addEventListener("scroll", scrollControll);
      return () => obj.removeEventListener("scroll", scrollControll);
    }
  }, [page]);

  async function fetchReportsData(pg, controller) {
    //debugger;
    let url = reportHelper.constructGetReportsUrl(props.tabID, props.searchText, props.deleted, pg);
    try {
      let reportsData = await fetch(url, {
        method: 'GET',
        signal: controller.signal,
        headers: {
          "Authorization": `Token ${props.userKey}`,
          'Content-Type': 'application/json',
        }
      });
      switch (reportsData.status) {
        case 200:
          let data = await reportsData.json();
          startLoadNext(false)
          props.startLoad(false)
          if (data.results.length !== 0) {
            props.setReportsData(props.reportsData.concat(data.results));
            store.dispatch({
              type: "SET_PK",
              payloadReportPK: data.results[0].pk
            });
          }
          if (data.meta.nextPageUri) {
            setPage({ currentPage: pg + 1, canNext: true });
          }
          else {
            setPage({ currentPage: pg, canNext: false });
          }
          if (data.results.length > 0) {
            if (data.results[0].isShared === undefined) {
              if (store.getState().editorState.isShared === true) {
                tryLoadReport(data.results[0].pk, data.results[0].pk);
              }
              else {
                if (_.find(store.getState().leftMenu.reportsData, { pk: store.getState().leftMenu.selectedReport }) !== undefined) {
                  tryLoadReport(store.getState().leftMenu.selectedReport, store.getState().leftMenu.selectedReport);
                }
                else {
                  tryLoadReport(data.results[0].pk, data.results[0].pk);
                }
              }
            }
          }
          return
        case 403:
          store.dispatch({
            type: "SET_NOPAYMENTPANEL"
          });
          return;
        case 401:
          createPopupReportList(store,
            ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
            "O", {
            "O": () => {
              document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
              document.location.reload(true);
            }
          });
          return
        default: {
          createPopupReportList(store,
            ["Не удалось получить список заключений!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте позднее."],
            "O", {});
        }
      }
    } catch (error) {

    }
  }

  const tryDeleteOrRestore = (pk, index) => {
    if (pk === props.selectedReport) {
      tmpObj.check(() => {
        tryCoR(pk, index);
      });
    } else {
      tryCoR(pk, index);
    }
  };

  const tryLoadReport = (pk, index) => {
    tmpObj.check(() => {
      LRD.loadReport(pk, index);
    });
  };

  const tryCoR = (pk, index) => {
    $.ajax({
      type: "PUT",
      url: `${props.url}/examinations/reports/${pk}/${props.deleted ? "clear" : "mark"}_deleted/`,
      contentType: "application/json",
      crossDomain: true,
      headers: {
        "Authorization": `Token ${props.userKey}`
      },
      success: function () {
        if (!props.deleted && props.selectedReport === pk) store.dispatch({ type: "SET_DEFAULT_RIGHTPANEL" })
        props.DoRreport(index, props.selectedReport === index ? true : false);
      },
      error: function (data) {
        if (data.responseText === "Report deleted" || data.responseText === "Report restored") {
          props.DoRreport(index, props.selectedReport === index ? true : false);
          if (store.getState().leftMenu.reportsData.length > 0) {
            tryLoadReport(store.getState().leftMenu.reportsData[0].pk, store.getState().leftMenu.reportsData[0].pk);
          }
          else {
            store.dispatch({
              type: "SET_DEFAULT_RIGHTPANEL"
            });
          }
        }
        else {
          if (data.status === 401) {
            createPopupReportList(store,
              ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
              "O", {
              "O": () => {
                document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                document.location.reload(true);
              }
            });
          }
          else {
            createPopupReportList(store,
              ["Не удалось выполнить действие над заключением!", <br key={"createPopupReportList_" + index} />, "Пожалуйста, попробуйте позднее."],
              "O", {});
          }
        }
      }
    });
  };

  function scrollTop() {
    $(".reportEditorContainer").scrollTop(0);
  }

  function createReports() {
    if (props.isLoad) return <img className="reportsLoader" src={preloader} alt="preloader" />
    if (props.reportsData.length > 0) {
      return props.reportsData.map((result, index) => {
        return (
          <div key={"report_" + index}>
            <div className={`newReportContainer ${props.selectedReport === result.pk && "reportContainerSelect"}`}
                 onClick={() => { scrollTop(); tryLoadReport(result.pk, result.pk);  }}>
              <div>
                <div className="newTextContainer">
                  <div><span>{convertDate(result.date)}</span></div>
                  <div><span>{result.patient_name}</span></div>
                  <div>
                    <img src={imageSwitcher(result.examination_area.icon)} alt={result.examination_area.icon} title={result.examination_area.icon} />
                    <span>{result.examination_area.area}</span>
                  </div>
                  {!result.isShared && <div className="DoRicon" onClick={(e) => { e.stopPropagation(); tryDeleteOrRestore(result.pk, index); }}>{props.deleted ? returnIcon : deleteIcon}</div>}
                </div>
                <div>
                </div>
              </div>
            </div>
            <div className="experimental"></div>
          </div>
        );
      });
    }
    else {
      return (
        <span className={"notFoundText " + (props.searchStatus ? "" : "noReportsText")}>
          {props.searchStatus ?
              "Заключения с указанным текстом не найдены." :
              "Вы пока не сохранили ни одного заключения. Создайте новое или найдите нужное в нашей базе используя поиск выше по любым ключевым словам."
          }
        </span>
      );
    }
  }

  return (
    <div className="leftMenuReports" ref={reportList}>
      {createReports()}
      {isLoadNext && <img className="reportsLoader" src={preloader} alt="preloader" />}
    </div>
  );
}

const mapDispatchToProps = {
  parseData,
  setFirstReport,
  onSelectReport,
  DoRreport,
  onLoadReport,
  changeFieldOfStudy,
  addFoundedText,
  setReportsData,
  saveMD5,
  saveAdditionalMD5,
  saveMD5controls,
  setDefaultWorkstate
};
const mapStateToProps = store => {
  return {
    selectFirstReport: store.leftMenu.selectFirstReport,
    reportsData: store.leftMenu.reportsData,
    current: store.leftMenu.reportsData,
    tabID: store.leftMenu.currentTab.tabID,
    deleted: store.leftMenu.currentTab.deleted,
    userKey: store.settings.userKey,
    url: store.settings.url,
    selectedReport: store.leftMenu.selectedReport,
    searchText: store.leftMenu.search.text,
    searchStatus: store.leftMenu.search.status,
    rightPanel: store.leftMenu.rightPanel
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);