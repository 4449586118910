import React from "react";
import { connect } from "react-redux";
import "./workPanel.css";
import CategorySelector from "./categorySelector/categorySelector";
import ReportEditor from "./reportEditor/reportEditor";

function panelSelect (props) {

  const rightPanelSwitcher = () => {
    switch (props.rightPanel) {
      case "CategorySelector":
        return <CategorySelector />
      case "ReportEditor":
        return <ReportEditor />
      default:
        return <div  className="mainPanelHelp"/>
               
    }
  }
  return (
    <div style={{ display: "flex", justifyContent: "center", overflow: "hidden"}}>
      {rightPanelSwitcher()}
    </div>
  );
}
const mapStateToProps = state => {
  return {
    rightPanel: state.leftMenu.rightPanel,
  };
};
export default connect(mapStateToProps)(panelSelect);