import React, { Component } from "react";
import $ from "jquery";
import StatusMessage from "../../../components/statusMessage/statusMessage";


export default class restoretView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusMessage: false
    };
  }
  checkInputData() {
    this.setState(
      {
        statusMessage: false,
        inputEmailError: this.state.inputEmail ? false : true,
      },
      () => {
        if (!this.state.inputEmailError) {
          this.tryRestore();
        }
      });
  }
  tryRestore() {
    var tmp = this;
    $.ajax({
      type: "POST",
      url: `${this["props"].url}/auth/password-reset/`,
      contentType: "application/json",
      data: "{\"email\" : \"" + this.state.inputEmail + "\"}",
      success: function () {
        tmp.setState({
          statusMessage: true,
          statusMessageType: "successType",
          statusMessageText: "Ссылка для смены пароля отправлена на почту."
        });

      },
      error: function (data) {
        if (data.readyState === 0) {
          return tmp.setState({
            statusMessage: true,
            statusMessageType: "unknownError",
            statusMessageText: "Что-то пошло не так... Пожалуйста, попробуйте еще раз позднее."
          });
        }
        if (data.status !== 404 && data.status !== 400 && data.status !== 500) {
          tmp.emailErrorHandler(data.responseJSON.email[0]);
        }
        else {
          tmp.setState({
            statusMessage: true,
            statusMessageType: "unknownError",
            statusMessageText: "Что-то пошло не так... Пожалуйста, попробуйте еще раз позднее."
          });
        }
      }
    });
  }
  emailErrorHandler(code) {
    switch (code) {
    case "User with this email already exists":
      this.setState({
        ErrorText: "Адрес уже занят, используйте другой",
        inputEmailError: true
      });
      break;
    case "Введите корректный адрес электронной почты.":
      this.setState({
        ErrorText: "Введите корректный адрес электронной почты",
        inputEmailError: true
      });
      break;
    default:
    }
  }
  onChangeInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: false
    });
  }

  render() {
    return (
      <div className="authBody">
        <div className="authLogo">
          <img alt="logo" src="/qr_logo_2_white.png" />
        </div>
        <div className="authContainer">
          <div className="inputGroup">
            <span className="restoreText">Введите почту, указанную при регистрации:</span>
            <div className="dataInput">
              <input autoFocus={true} className="customInput"
                name="inputEmail"
                onChange={e => { this.onChangeInput(e); }}
                placeholder={this["props"].email}
                selectAllOnFocus="true"
                style={{ "borderColor": this.state.inputEmailError ? "#BD3131" : "#A6BDCA" }} />
              {this.state.inputEmailError && <StatusMessage type="smallError smallErrorAuth" errorText="Укажите свою электронную почту" />}
            </div>
          </div>
          <div>
            <div><button className="customButton" onClick={() => { this.checkInputData(); }}>Сбросить пароль</button></div>
          </div>
          <div className="bottomLink">
            <a href onClick={() => { this["props"].changeView("LOGIN"); }}>Авторизация</a>
          </div>
        </div>
        {this.state.statusMessage && <StatusMessage type={this.state.statusMessageType} errorText={this.state.statusMessageText} />}
      </div>
    );
  }
}