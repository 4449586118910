export const toogleFastInsert = () =>{
    return{
        type:"TOOGLE_FAST_INSERT"
    }
}
export const onChangeSelectedTab = (index) =>{
    return{
        type:"CHANGE_SELECTED_TAB",
        payloadTabIndex:index
    }
}
export const onChangeSelectedReport = (index) =>{
    return{
        type:"CHANGE_SELECTED_REPORT",
        payloadReportIndex:index
    }
}
export const onChangeSearchText= (text) =>{
    return{
        type:"CHANGE_SEARCH_TEXT_FI",
        payloadText:text
    }
}
export const addFoundedReports= (array) =>{
    return{
        type:"ADD_FOUND_REPORTS",
        payloadReportsArray:array
    }
}
export const addFoundedText= (text) =>{
    return{
        type:"ADD_FAST_TEXT",
        payloadReportsText:text
    }
}
