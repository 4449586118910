import React from "react";
import "./css/subscriptionFrom.css";

export default class subscriptionFrom extends React.Component {
  render() {
    return (
      <div className="popupBody">
        <div className="informationText">Оформить подписку<br />(ТУТ БУДЕТ ВИДЖЕТ CLOUDPAYMENTS)</div>
        <div className="cardData">
          <div>
            <span>Номер карты</span><br />
            <input placeholder='5536 9137 5677 3425'></input>
          </div>
          <div>
            <span>Срок действия</span><br />
            <input className="small" placeholder='09/23'></input>
          </div>
        </div>
        <div className="cardData">
          <div>
            <span>Имя</span><br />
            <input placeholder='KONSTANTIN KHARLIN'></input>
          </div>
          <div>
            <span>CVC/CVV</span><br />
            <input className="small" placeholder='***'></input>
          </div>
        </div>
        <div className="buttonContainer">
          <div>Назад</div>
          <div>Подписаться</div>
        </div>
      </div>
    );
  }
}