import store from "../../../../index";
/**
 * Статусы изображений в хранилище:
 * 1. onServer - изображение есть на сервере
 * 2. delete - изображение удалено пользователем, но на сервер еще не отправлена информация
 * 3. add - изображение добавлено пользователем, но на сервер еще не отправлена информация
 * data... - изображение в base64 загружено в браузер, но на сервер еще не загружено
 */

export default class workWithStorage {
    currentDataStorage = {};

    changeReportStatus = (reportStatus) => {
        let reportId = "newReport";
        if (store.getState().editorState.reportPK !== null) {
            reportId = store.getState().editorState.reportPK;
        }
        this.getDataFromStorage();
        if (this.currentDataStorage !== undefined) {
            if (this.currentDataStorage["reportId"] === reportId) {
                this.currentDataStorage["reportStatus"] = reportStatus;
                this.setData(reportId, this.currentDataStorage);
            }
        }
    }

    changeTmpImageName = (oldId, newId) => {
        let reportId = "newReport";
        if (store.getState().editorState.reportPK !== null) {
            reportId = store.getState().editorState.reportPK;
        }
        this.getDataFromStorage();
        if (this.currentDataStorage !== undefined) {
            if (this.currentDataStorage["reportId"] === reportId) {
                let iteration = 0;
                if (this.currentDataStorage.data[newId] !== undefined) {
                    iteration = ++this.currentDataStorage.data[newId].iteration;
                }
                this.currentDataStorage.data[newId] = {
                    data: "",
                    status: "add",
                    iteration: iteration
                }
                delete this.currentDataStorage.data[oldId];
                this.setData(reportId, this.currentDataStorage);
            }
        }
    }

    didChange = () => {
        this.getDataFromStorage();
        let obj = this;
        if (this.currentDataStorage !== undefined) {
            let status = false;
            Object.keys(this.currentDataStorage.data).forEach(function (data) {
                if (obj.currentDataStorage.data[data].iteration === 0) {
                    status = true;
                }
            });
            return status;
        }
        else {
            return false;
        }
    }

    deleteImage = (imageId) => {
        let reportId = "newReport";
        if (store.getState().editorState.reportPK !== null) {
            reportId = store.getState().editorState.reportPK;
        }
        this.getDataFromStorage();
        if (this.currentDataStorage !== undefined) {
            if (this.currentDataStorage["reportId"] === reportId) {
                delete this.currentDataStorage.data[imageId];
                this.setData(reportId, this.currentDataStorage);
            }
        }
    }

    changeData = (imageId, data, status) => {
        let reportId = "newReport";
        if (store.getState().editorState.reportPK !== null) {
            reportId = store.getState().editorState.reportPK;
        }
        this.getDataFromStorage();
        if (this.currentDataStorage !== undefined) {
            //debugger;
            if (this.currentDataStorage["reportId"] === reportId) {
                if (data !== "") {
                    try {
                        data = this.currentDataStorage.data[imageId]["data"];
                    } catch (e) { }
                }
                let iteration = 0;
                if (this.currentDataStorage.data[imageId] !== undefined) {
                    iteration = ++this.currentDataStorage.data[imageId].iteration;
                }
                this.currentDataStorage.data[imageId] = {
                    data: data,
                    status: status,
                    iteration: iteration
                };
                this.setData(reportId, this.currentDataStorage);
            }
        }
    }

    deleteData = (reportId, imageId) => {
        this.getDataFromStorage();
        if (this.currentDataStorage !== undefined) {
            if (this.currentDataStorage["reportId"] === reportId) {
                if (this.currentDataStorage[imageId] !== undefined) {
                    delete this.currentDataStorage[imageId];
                }
                this.setData(reportId, this.currentDataStorage);
            }
        }
    }

    setData = (reportId, data) => {
        let obj = this;
        this.currentDataStorage.reportId = reportId;
        if (data["imagePaths"] !== undefined) {
            data["imagePaths"].forEach(function (data) {
                let iteration = 0;
                if (obj.currentDataStorage[data] !== undefined) {
                    iteration = ++obj.currentDataStorage[data].iteration;
                }
                obj.currentDataStorage[data] = {
                    reportStatus: obj.currentDataStorage[data].reportStatus,
                    data: "",
                    status: "onServer",
                    iteration: iteration
                };
            });
        }
        //debugger;
        localStorage.setItem("medReport", JSON.stringify(obj.currentDataStorage));
    }

    getDataFromStorage = () => {
        if (localStorage["medReport"] !== undefined) {
            try {
                this.currentDataStorage = JSON.parse(localStorage["medReport"]);
            }
            catch (e) {
                this.currentDataStorage = undefined;
            }
        }
    }

    onSelectReport = () => {
        //debugger;
        let tmpPK = "newReport";
        if (store.getState().editorState.reportPK != null) {
            tmpPK = store.getState().editorState.reportPK;
        }
        this.currentDataStorage = {
            reportId: tmpPK,
            reportStatus: "ready",
            data: {}
        };
        localStorage.setItem("medReport", JSON.stringify(this.currentDataStorage));
    }

    dataAnaliz = (parent, reportPK = store.getState().editorState.reportPK) => {
        //debugger;
        let obj = this;
        let status = false;
        let tmpPK = "newReport";
        if (store.getState().editorState.reportPK != null) {
            tmpPK = store.getState().editorState.reportPK;
        }
        this.getDataFromStorage();
        if (this.currentDataStorage !== undefined) {
            if (tmpPK !== "newReport") {
                if (this.currentDataStorage.reportStatus === "edite") {
                    let obj = this;
                    //debugger;
                    Object.keys(this.currentDataStorage.data).forEach(function (data) {
                        switch (obj.currentDataStorage.data[data].status) {
                            case "delete": {
                                //Сохраняем изображения
                                if (obj.currentDataStorage.data[data].iteration === 0) {
                                    parent.serverImage.backSaveImages(obj.currentDataStorage.data[data], data);
                                }
                                else {
                                    obj.deleteImage(data);
                                }
                                status = true;
                                break;
                            }
                            case "add": {
                                //Удаляем изображения
                                //debugger;
                                parent.serverImage.deleteImages(data);
                                status = true;
                                break;
                            }
                            default:
                                return;
                        }
                    });
                }
            }
            else {
                //debugger;
                //Позднее связывание
                //debugger;
                Object.keys(this.currentDataStorage.data).forEach(function (data) {
                    switch (obj.currentDataStorage.data[data].status) {
                        case "add": {
                            parent.serverImage.backSaveImages(obj.currentDataStorage.data[data], data, reportPK);
                            status = true;
                            break;
                        }
                        default:
                            return;
                    }
                });
            }
        }
        this.onSelectReport();
        return status;
    }
}