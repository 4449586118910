import React, { useState } from "react";
import "./fastInsert.css";
import { connect } from "react-redux";
import { changeReportText } from "../../../../../store/editorState/actions";
import { toogleFastInsert, onChangeSelectedTab, onChangeSearchText, addFoundedReports, addFoundedText } from "../../../../../store/fastInsert/actions";
import Tabs from "./tabs";
import ReportList from "./ReportList";
import store from "../../../../../index";
const Entities = require('html-entities').XmlEntities;

const FastInsert = (props) => {
  const [bool, setBool] = useState(false)

  const checkSearchStatus = () => {
    const entities = new Entities();
    let foundText = store.getState().fastInsertState.foundText;
    let searchText = "<span class='highlightText'>" + store.getState().fastInsertState.searchText + "</span>";
    //debugger;
    let tmpStr = foundText.replace(new RegExp(searchText, 'g'), "!@!");
    let tmpEncodeStr = entities.encode(tmpStr);
    let finalStr = tmpEncodeStr.replace(new RegExp('!@!', 'g'), "<span class='highlightText'>" + entities.encode(store.getState().fastInsertState.searchText) + "</span>");
    return finalStr;
  }

  return (
    <div className="fastInsertBody" >
      <div className="blackBackground" onClick={() => { props.toogleFastInsert(); }}></div>
      <div className="fastInsertContainer">
        <div>
          <div>
            <span>Режим быстрой вставки</span>
            <div onClick={() => { props.toogleFastInsert(); }}>
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.451 3.38849C16.6829 3.15664 16.6829 2.78074 16.451 2.54889C16.2192 2.31704 15.8433 2.31704 15.6114 2.54889L2.54889 15.6114C2.31704 15.8432 2.31704 16.2191 2.54889 16.451C2.78074 16.6828 3.15664 16.6828 3.38849 16.451L16.451 3.38849Z" fill="#ffffff" />
                <path d="M3.38849 2.54889C3.15664 2.31704 2.78074 2.31704 2.54889 2.54889C2.31704 2.78074 2.31704 3.15664 2.54889 3.38849L15.6114 16.451C15.8433 16.6828 16.2192 16.6828 16.451 16.451C16.6829 16.2191 16.6829 15.8432 16.451 15.6114L3.38849 2.54889Z" fill="#ffffff" />
              </svg>
            </div>
          </div>

        </div>
        <div className="insertArea">
          <div className="customLeftMenu">
            <Tabs tabsData={props.leftMenu.tabsData} startTab={props.leftMenu.currentTab.tabID} changeTab={props.onChangeSelectedTab} />
            <ReportList
              url={props.url}
              userKey={props.userKey}
              reports={props.leftMenu.reportsData}
              reportsData={props.fastInsertState.foundReports}
              text={props.fastInsertState.searchText}
              changeText={props.onChangeSearchText}
              tab={props.fastInsertState.tmpCurrentTab ? props.fastInsertState.tmpCurrentTab : props.leftMenu.currentTab.tabID}
              addFoundedReports={props.addFoundedReports}
              addFoundedText={props.addFoundedText}
              loadingBool={bool}
              loading={setBool} />
          </div>
          <div className="textColumn">
            <span className="categoryHeader categoryHeaderFastInsert">ВЫБРАННОЕ ИЗ ПОИСКА ЗАКЛЮЧЕНИЕ</span>
            <div id="test" contentEditable="false" dangerouslySetInnerHTML={{ __html: checkSearchStatus() }}></div>
          </div>
          <div className="textColumn">
            <span className="categoryHeader categoryHeaderFastInsert">ТЕКУЩЕЕ РЕДАКТИРУЕМОЕ ЗАКЛЮЧЕНИЕ</span>
            <textarea onBlur={(e) => { props.changeReportText(e.target.value); }}>{props.reportText}</textarea>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  toogleFastInsert,
  changeReportText,
  onChangeSelectedTab,
  onChangeSearchText,
  addFoundedReports,
  addFoundedText
};
const mapStateToProps = (state) => {
  return {
    url: state.settings.url,
    userKey: state.settings.userKey,
    fastInsertState: state.fastInsertState,
    leftMenu: state.leftMenu,
    reportText: state.editorState.generatedText
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FastInsert);