import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import AllReducers from "./store/reducers";
import "./index.css";
import "./css/auth.css";
import SwitcherSpaces from "./views/switcherSpaces";
import * as serviceWorker from "./serviceWorker";

const store = createStore(AllReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export default store;

ReactDOM.render(
  <Provider store={store}>
    <SwitcherSpaces />
  </Provider>
  , document.getElementById("root"));

serviceWorker.unregister();