import $ from "jquery";
import store from "../../../../index";
import _ from 'lodash';
import workWithStorage from "./workWithStorage";
import GlobalSettings from "../../../../settings/GlobalSettings";

export default class workWithServer {
    imgSrc = [];
    imgData = [];
    parent = {};
    WWS = new workWithStorage();
    tmpImage = {};

    constructor(parent) {
        this.parent = parent;
    }

    base64Encode(str) {
        var CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        var out = "", i = 0, len = str.length, c1, c2, c3;
        while (i < len) {
            c1 = str.charCodeAt(i++) & 0xff;
            if (i === len) {
                out += CHARS.charAt(c1 >> 2);
                out += CHARS.charAt((c1 & 0x3) << 4);
                out += "==";
                break;
            }
            c2 = str.charCodeAt(i++);
            if (i === len) {
                out += CHARS.charAt(c1 >> 2);
                out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
                out += CHARS.charAt((c2 & 0xF) << 2);
                out += "=";
                break;
            }
            c3 = str.charCodeAt(i++);
            out += CHARS.charAt(c1 >> 2);
            out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
            out += CHARS.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
            out += CHARS.charAt(c3 & 0x3F);
        }
        return "data:image/jpeg;base64," + out;
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    deleteFromRedux(data) {
        let tmpArray = [];
        this.imgData = store.getState().editorState.imageArray;
        let obj = this;
        let needImages = _.differenceWith(obj.imgData, data, _.isEqual);
        //debugger;
        this.imgData.forEach(function (data) {
            if (Object.keys(data)[0] !== data) {
                tmpArray.push(data);
            }
        });
        this.imgData = tmpArray;
        //debugger;
        store.dispatch({
            type: "SET_IMAGES_ARRAY",
            imgData: needImages
        });
    }

    deleteImages = (imgId) => {
        //debugger;
        //store.dispatch({
        //    type: "CHANGE_POPUP_DATA",
        //    popupData: [{ text: ["preloader_Удаление изображения из backup"] }]
        //});
        $.ajax({
            type: "DELETE",
            url: GlobalSettings.apiUrl + imgId,
            headers: {
                "Authorization": `Token ${store.getState().settings.userKey}`
            },
            success: function (data) {
                //store.dispatch({
                //    type: "POPUP_EXPERIMENT",
                //    serchText: "preloader_Удаление изображения из backup"
                //});
            },
            error: function () { }
        });
    }

    getImagesFromServer = (imgId, status) => {
        var obj = this;
        this.imgData = store.getState().editorState.imageArray;
        $.ajax({
            type: "GET",
            url: GlobalSettings.apiUrl + imgId,
            //async: false,
            headers: {
                "Authorization": `Token ${store.getState().settings.userKey}`
            },
            mimeType: "text/plain; charset=x-user-defined",
            success: function (data) {
                //console.log("Ответ сервера с изображением под id: " + imgId);
                $(".loader").hide();
                let tmpObj = {};
                tmpObj[imgId + "_fromServer"] = obj.base64Encode(data);
                obj.imgData.push(tmpObj);
                //obj.WWS.changeData(store.getState().editorState.reportPK, imgId, obj.base64Encode(data), "onServer");
                store.dispatch({
                    type: "SET_IMAGES_ARRAY",
                    imgData: obj.imgData
                });
                if (status !== undefined) {
                    store.dispatch({
                        type: "SET_TMP_IMAGES_ARRAY",
                        tmpImgData: obj.imgData
                    });
                }
            },
            error: function () { }
        });
    }

    getImagesIdFromServer = (status) => {
        //debugger;
        if (store.getState().editorState.reportPK != null) {
            var obj = this;
            store.dispatch({
                type: "SET_IMAGES_ARRAY",
                imgData: []
            });
            /*store.dispatch({
                type: "CHANGE_POPUP_DATA",
                popupData: [{ text: ["preloader_Загрузка изображений"] }]
            });*/
            $(".loader").show();

            $.ajax({
                type: "GET",
                url: GlobalSettings.apiUrl + "/images?reportId=" + store.getState().editorState.reportPK,
                contentType: false,
                processData: false,
                headers: {
                    "Authorization": `Token ${store.getState().settings.userKey}`
                },
                success: function (data) {
                    for (var i = 0; i < data.imagePaths.length; ++i) {
                        obj.imgSrc[i] = data.imagePaths[i];
                        obj.getImagesFromServer(data.imagePaths[i], status);
                    }

                    if (data.imagePaths.length === 0) {
                        $(".loader").hide();
                    }
                    //obj.WWS.changeData(obj.tmpImage, "", "add");
                    //debugger;
                    /*store.dispatch({
                        type: "POPUP_EXPERIMENT",
                        serchText: "preloader_Загрузка изображений"
                    });*/
                    //if (status != undefined) {
                    ///    store.dispatch({
                    //        type: "SET_MD5_IMAGES",
                    //        md5Images: md5.hex_md5(JSON.stringify(store.getState().editorState.imageArray))
                    //    });
                    //}
                },
                error: function () { }
            });
        }
    }

    backSaveImages = (imgData, id, reportPK = store.getState().editorState.reportPK) => {
        //debugger;
        var obj = this;
        var formData = new FormData();
        formData.append('file', this.dataURLtoFile(imgData.data, String(new Date().getTime()) + ".png"));
        //store.dispatch({
        //    type: "CHANGE_POPUP_DATA",
        //    popupData: [{ text: ["preloader_Сохранение изображения из backup"] }]
        //});
        $.ajax({
            type: "POST",
            url: GlobalSettings.apiUrl + "/images?reportId=" + reportPK,
            data: formData,
            contentType: false,
            processData: false,
            //async: false,
            headers: {
                "Authorization": `Token ${store.getState().settings.userKey}`
            },
            success: function (data) {
                //obj.WWS.changeReportStatus("ready");
                obj.WWS.deleteImage(id);
                //store.dispatch({
                //    type: "POPUP_EXPERIMENT",
                //    serchText: "preloader_Сохранение изображения из backup"
                //});
            },
            error: function () { }
        });
    }

    saveImages = (imgData, single = true) => {
        if (store.getState().editorState.reportPK != null) {
            var obj = this;
            let status = false;
            var formData = new FormData();
            this.tmpImage = String(Object.keys(store.getState().editorState.imageArray[store.getState().editorState.imageArray.length - 1])[0]);
            for (var i = 0; i < imgData.length; ++i) {
                if (String(Object.keys(imgData[i])[0]).indexOf("_fromServer") === -1) {
                    //debugger;
                    formData.append('file', this.dataURLtoFile(imgData[i][String(Object.keys(imgData[i])[0])], String(Object.keys(imgData[i])[0]) + ".png"));
                    status = true;
                }
            }
            if (status === true) {
                //store.dispatch({
                //    type: "CHANGE_POPUP_DATA",
                //    popupData: [{ text: ["preloader_Сохранение изображения на сервер"] }]
                //});
                $(".preloaderText").css("display", "none");
                $.ajax({
                    type: "POST",
                    url: GlobalSettings.apiUrl + "/images?reportId=" + store.getState().editorState.reportPK,
                    data: formData,
                    contentType: false,
                    processData: false,
                    //async: false,
                    headers: {
                        "Authorization": `Token ${store.getState().settings.userKey}`
                    },
                    success: function (data) {
                        //obj.WWS.changeReportStatus("ready");
                        //debugger;
                        //store.dispatch({
                        //    type: "POPUP_EXPERIMENT",
                        //    serchText: "preloader_Сохранение изображения на сервер"
                        //});
                        $(".preloaderText").css("display", "none");
                        obj.WWS.changeTmpImageName(obj.tmpImage, "/images/" + data);
                        if (single === true) {
                            obj.getImagesIdFromServer();
                        }
                    },
                    error: function () { }
                });
            }
        }
    }
}