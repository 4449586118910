import React from "react";
import $ from "jquery";
import store from "../../../../index";
import md5 from "react-native-md5";
import workWithServer from "../reportEditor/workWithServer";
import { createPopupReportList } from "../../../../store/editorState/actions";

class loadReportsData {
    serverImage = new workWithServer();

    loadExaminationsNavigation = (reportIndex) => {
        let obj = this;
        if (store.getState().editorState.templatePK != null) {
            //debugger;
            $.ajax({
                type: "GET",
                url: `${store.getState().settings.url}/examinations/navigation/${
                    store.getState().editorState.templatePK
                    }/template/`,
                contentType: "application/json",
                crossDomain: true,
                headers: {
                    "Authorization": `Token ${store.getState().settings.userKey}`
                },
                success: function (data) {
                    //debugger;
                    //obj.serverImage.getImagesIdFromServer("onLoad");
                    //obj.serverImage.WWS.dataAnaliz(obj);
                    obj.serverImage.getImagesIdFromServer("onLoad");
                    store.dispatch({
                        type: 'PARSE_DATA',
                        payloadData: data,
                    });
                    let res = store.getState().editorState.categoryArray.map(a => ({ ...a }));
                    let tmpCategoryArray = {};
                    res.map(function (data, index) {
                        delete (data.visible);
                        return tmpCategoryArray[index] = data;
                    });
                    store.dispatch({
                        type: "SET_MD5_CONTROLS",
                        md5: md5.hex_md5(JSON.stringify(tmpCategoryArray))
                    });
                },
                error: function (data) {
                    if (data.status === 401) {
                        createPopupReportList(store,
                            ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
                            "O", {
                            "O": () => {
                                document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                                document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                                document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                                document.location.reload(true);
                            }
                        });
                    }
                    else {
                        createPopupReportList(store,
                            ["Не удалось получить данные по заключению!", <br />, "Пожалуйста, попробуйте позднее."],
                            "O", {});
                    }
                }
            });
        }
    }

    loadReport = (pk, reportIndex) => {
        let obj = this;
        $.ajax({
            type: "GET",
            url: `${store.getState().settings.url}/examinations/reports/${pk}/`,
            contentType: "application/json",
            crossDomain: true,
            headers: {
                "Authorization": `Token ${store.getState().settings.userKey}`
            },
            success: function (data) {
                //debugger;
                store.dispatch({
                    type: "SET_MD5_ADDITIONAL",
                    md5: md5.hex_md5(data.date + data.patient_name + data.patient_date_of_birth)
                });
                store.dispatch({
                    type: "SET_MD5",
                    md5: data.text ? md5.hex_md5(data.text) : ""
                });
                store.dispatch({
                    type: "ON_LOAD_REPORT",
                    payloadReportData: data,
                    payloadReportIndex: reportIndex
                });
                store.dispatch({
                    type: "ADD_FOUND_TEXT",
                    payloadReportsText: data.text
                });
                obj.serverImage.WWS.dataAnaliz(obj);
                if (data.template != undefined) {
                    obj.loadExaminationsNavigation(pk);
                }
                else {
                    store.dispatch({
                        type: "SET_CATEGORY_ARRAY"
                    });
                    store.dispatch({
                        type: "SET_MD5_CONTROLS",
                        md5: store.getState().editorState.defaultMD5_categoryArray
                    });
                    store.dispatch({
                        type: "SET_REPORT_INDEX",
                        payloadReportIndex: data.pk
                    });
                    obj.serverImage.getImagesIdFromServer("onLoad");
                }
            },
            error: function (data) {
                if (data.status === 401) {
                    createPopupReportList(store,
                        ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
                        "O", {
                        "O": () => {
                            document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                            document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                            document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                            document.location.reload(true);
                        }
                    });
                }
                else {
                    createPopupReportList(store,
                        ["Не удалось получить шаблон заключения!", <br key={"createPopupReportList_" + reportIndex} />, "Пожалуйста, попробуйте позднее."],
                        "O", {});
                }
            }
        });
    };
}

const LRD = new loadReportsData();
export default LRD;