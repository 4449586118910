import { combineReducers } from 'redux';
import defaultTemp from './defaultTemp/reducers';
import { settingsReducers } from './settings/reducers';
import { workStateReducers, arraysReducers } from './workState/reducers';
import { leftMenuReducer } from './leftMenu/reducers';
import { editorStateReducer } from './editorState/reducers';
import { fastInsertReducer } from './fastInsert/reducers';
import { paymentsReducer } from "./payments/reducers";

const allReducers = combineReducers(
    {
        defaultTemp: defaultTemp,
        settings: settingsReducers,
        workState: workStateReducers,
        arrays: arraysReducers,
        leftMenu: leftMenuReducer,
        editorState: editorStateReducer,
        fastInsertState: fastInsertReducer,
        payments: paymentsReducer
    }
);

export default allReducers;