import React from "react";
import { createPopupReportList } from "../../store/editorState/actions";
import "./css/subscriptionFrom.css";
import store from "../../index";
import { connect } from "react-redux";
import $ from "jquery";
import tmpObj from "../workSpace/workPanel/reportEditor/checkModReport";

class saveAndSend extends React.Component {
  constructor(props) {
    super(props);
  }

  sendMessage() {
    let obj = this;
    tmpObj.check((pk) => {
      let currentEmail = $(".saveAndSend div input")[0].value;
      let currentPK = store.getState().editorState.reportPK;
      if (currentEmail === "") {
        currentEmail = store.getState().settings.userMail;
      }
      $.ajax({
        type: "POST",
        url: `${this["props"].settings.url}/examinations/reports/${pk}/send/`,
        contentType: "application/json",
        data: JSON.stringify({ "email": currentEmail }),
        headers: {
          "Authorization": `Token ${store.getState().settings.userKey}`
        },
        crossDomain: true,
        success: function () {
          createPopupReportList(store,
            ["Заключение отправлено на почту ", currentEmail],
            "O", {}, "sucsess");
          if (obj.props.data.buttonEvent["sAs"] !== undefined) {
            obj.props.data.buttonEvent["sAs"]();
          }
        },
        error: function (data) {
          if (data.responseText === "Report has been sent") {
            createPopupReportList(store,
              ["Заключение сохранено и отправлено на почту ", currentEmail],
              "O", {}, "sucsess");
            //if (obj.props.data.buttonEvent["sAs"] !== undefined) {
            //  obj.props.data.buttonEvent["sAs"]();
            //}
          }
          else {
            if (data.status == 401) {
              createPopupReportList(store,
                ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
                "O", {
                "O": () => {
                  document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.location.reload(true);
                }
              });
            }
            else {
              createPopupReportList(store,
                ["Не удалось отправить заключение!", <br key={"br_" + currentPK} />, "Пожалуйста, попробуйте позднее."],
                "O", {});
            }
          }
        }
      });
      this.defaultButtonEvent();
      store.dispatch({
        type: "SELECT_FIRST_REPORT"
      });
      store.dispatch({
        type: "SET_REPORTSDATA",
        payloadReportsData: []
      });
    }, false, true, true);
  }

  defaultButtonEvent() {
    let popupData = store.getState().workState.popupData;
    let tmp = this;
    let tmpArray = [];
    popupData.forEach(function (data) {
      if (tmp.props.data !== data) {
        tmpArray.push(data);
      }
    });
    store.dispatch({
      type: "CHANGE_POPUP_DATA",
      popupData: tmpArray
    });
  }

  render() {
    return (
      <div className="popupBody">
        <div className="saveAndSend">
          <div>
            <span>Почта</span><br />
            <input placeholder={store.getState().settings.userMail} onKeyPress={(e) => { if (e.key === "Enter") this.sendMessage() }}></input>
          </div>
        </div>
        <div className="buttonContainer">
          <div onClick={() => { this.defaultButtonEvent(); }}>Отмена</div>
          <div onClick={() => { this.sendMessage(); }}>Сохранить и отправить</div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  createPopupReportList
};

const mapStateToProps = state => {
  return {
    popupData: state.workState,
    settings: state.settings
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(saveAndSend);