import React from "react";
import "./css/subscriptionFrom.css";
import "./css/simple.css";
import store from "../../index";
import { connect } from "react-redux";

class SubscriptionFrom extends React.Component {
  constructor(props) {
    super(props);
  }

  buttonType = {
    //Отмена - Продолжить
    "C_Cont": <div className="C_SaP">
      <div onClick={() => { this["props"].data.buttonEvent["C"] !== undefined ? this["props"].data.buttonEvent["C"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Отмена</div>
      <div onClick={() => { this["props"].data.buttonEvent["Cont"] !== undefined ? this["props"].data.buttonEvent["Cont"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Продолжить</div>
    </div>,
    //Отмена - Сохранить и отправить
    "C_SaP": <div className="C_SaP">
      <div>Отмена</div>
      <div>Сохранить и отправить</div>
    </div>,
    //Отмена - Не сохранять - Сохранить
    "C_Ns_S": <div className="C_Ns_S">
      <div onClick={() => { this["props"].data.buttonEvent["C"] !== undefined ? this["props"].data.buttonEvent["C"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Отмена</div>
      <div onClick={() => { this["props"].data.buttonEvent["Ns"] !== undefined ? this["props"].data.buttonEvent["Ns"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Не сохранять</div>
      <div onClick={() => { this["props"].data.buttonEvent["S"] !== undefined ? this["props"].data.buttonEvent["S"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Сохранить</div>
    </div>,
    //Отмена - Сгенерировать - Сохранить
    "C_G_S": <div className="C_G_S">
      <div onClick={() => { this["props"].data.buttonEvent["O"] !== undefined ? this["props"].data.buttonEvent["O"]() : this.defaultButtonEvent(); }}>Отмена</div>
      <div onClick={() => { this["props"].data.buttonEvent["G"] !== undefined ? this["props"].data.buttonEvent["G"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Сгенерировать новый текст по шаблону</div>
      <div onClick={() => { this["props"].data.buttonEvent["S"] !== undefined ? this["props"].data.buttonEvent["S"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Сохранить текущий текст</div>
    </div>,
    //Отмена - Удалить
    "C_D": <div className="C_D">
      <div onClick={() => { this["props"].data.buttonEvent["C"] !== undefined ? this["props"].data.buttonEvent["C"]() : this.defaultButtonEvent(); }}>Отмена</div>
      <div onClick={() => { this["props"].data.buttonEvent["D"] !== undefined ? this["props"].data.buttonEvent["D"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Удалить</div>
    </div>,
    //Отмена - Сгенерировать
    "C_G_Cont": <div className="C_G_Cont">
      <div onClick={() => { this["props"].data.buttonEvent["C"] !== undefined ? this["props"].data.buttonEvent["C"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Отмена</div>
      <div onClick={() => { this["props"].data.buttonEvent["G"] !== undefined ? this["props"].data.buttonEvent["G"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Сгенерировать</div>
      <div onClick={() => { this["props"].data.buttonEvent["Cont"] !== undefined ? this["props"].data.buttonEvent["Cont"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Продолжить</div>
    </div>,
    //Отмена - Сохранить
    "C_S": <div className="C_Subs">
      <div onClick={() => { this["props"].data.buttonEvent["C"] !== undefined ? this["props"].data.buttonEvent["C"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Отмена</div>
      <div onClick={() => { this["props"].data.buttonEvent["S"] !== undefined ? this["props"].data.buttonEvent["S"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Сохранить</div>
    </div>,
    //Назад - Подписаться
    "C_Subs": <div className="C_Subs">
      <div>Назад</div>
      <div>Подписаться</div>
    </div>,
    //Назад - Выйти
    "C_E": <div className="C_E">
      <div onClick={() => { this["props"].data.buttonEvent["C"] !== undefined ? this["props"].data.buttonEvent["C"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Назад</div>
      <div onClick={() => { this["props"].data.buttonEvent["E"] !== undefined ? this["props"].data.buttonEvent["E"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Выйти</div>
    </div>,
    //Назад - Отписаться
    "C_Unsub": <div className="C_Unsub">
      <div onClick={() => { this["props"].data.buttonEvent["C"] !== undefined ? this["props"].data.buttonEvent["C"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Назад</div>
      <div onClick={() => { this["props"].data.buttonEvent["Unsub"] !== undefined ? this["props"].data.buttonEvent["Unsub"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Отписаться</div>
    </div>,
    //Ok
    "O": <div className="O">
      <div onClick={() => { this["props"].data.buttonEvent["O"] !== undefined ? this["props"].data.buttonEvent["O"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>ОК</div>
    </div>,
    "Ok_Copy": <div className="O">
      <div onClick={() => { this["props"].data.buttonEvent["Ok"] !== undefined ? this["props"].data.buttonEvent["Ok"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>ОК</div>
      <div onClick={() => { this["props"].data.buttonEvent["Copy"] !== undefined ? this["props"].data.buttonEvent["Copy"](this.defaultButtonEvent()) : this.defaultButtonEvent(); }}>Скопировать</div>
    </div>,
    "default": ""
  }

  defaultButtonEvent() {
    let popupData = store.getState().workState.popupData;
    let tmp = this;
    let tmpArray = [];
    popupData.forEach(function (data) {
      if (tmp.props.data !== data) {
        tmpArray.push(data);
      }
    });
    this["props"].dispatch({
      type: "CHANGE_POPUP_DATA",
      popupData: tmpArray
    });
  }

  render() {
    let maxLength = 0;
    for (let i in this["props"].text) {
      if (typeof (this["props"].text[i]) === "string") {
        if (this["props"].text[i].length * 9 > maxLength) {
          maxLength = this["props"].text[i].length * 9;
        }
      }
    }
    switch (this["props"].buttonType) {
      case "C_Ns_S": {
        maxLength = 363;
        break;
      }
      case "C_G_S": {
        maxLength = 622;
        break;
      }
      case "C_E": {
        maxLength = 278;
        break;
      }
    }

    return (
      <div style={{ width: maxLength }} className="popupBody">
        <div className="informationText">{this["props"].text}</div>
        {this.buttonType[this["props"].buttonType]}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    popupData: state.workState
  };
};
export default connect(mapStateToProps)(SubscriptionFrom);