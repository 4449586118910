import React from "react";

export const imageSwitcher = (title) => {
    switch (title) {
        case "orange":
            return orange;
        case "green":
            return green;
        case "red":
            return red;
        default:
            return red;
    }
};

export const orange = <svg width="13" height="13" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5.5C11 2.46211 8.53789 0 5.5 0C2.46211 0 0 2.46211 0 5.5C0 8.53789 2.46211 11 5.5 11C8.53789 11 11 8.53789 11 5.5ZM9.70449 3.35801L4.52676 8.53574L4.15723 8.90527L1.29551 6.04355L2.55879 4.78027L4.15938 6.38086L8.44336 2.09688L9.70449 3.35801Z" fill="#FF9900" />
</svg>
    ;

export const green = <svg width="13" height="13" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 5.5C11 2.46211 8.53789 0 5.5 0C2.46211 0 0 2.46211 0 5.5C0 8.53789 2.46211 11 5.5 11C8.53789 11 11 8.53789 11 5.5ZM9.70449 3.35801L4.52676 8.53574L4.15723 8.90527L1.29551 6.04355L2.55879 4.78027L4.15938 6.38086L8.44336 2.09688L9.70449 3.35801Z" fill="#41AD49" />
</svg>;

export const red = <svg width="13" height="13" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.38916 1.61084C7.24134 -0.536946 3.75862 -0.536946 1.61084 1.61084C-0.536946 3.75866 -0.536946 7.24138 1.61084 9.38916C3.75866 11.5369 7.24138 11.5369 9.38916 9.38916C11.5369 7.24138 11.5369 3.75862 9.38916 1.61084ZM5.51284 6.43596L2.92296 9.02584L1.9742 8.07707L4.56404 5.4872L1.99984 2.92296L2.92296 1.99984L5.4872 4.56404L8.05143 1.99984L9.0002 2.9486L6.43596 5.5128L9.0002 8.07704L8.07707 9.00016L5.51284 6.43596Z" fill="#BD3131" />
</svg>;